import React, { useCallback, useContext, useRef, useState } from "react";
import Moment from "moment";
import Collapsible from "react-collapsible";
import { Tab, Tabs } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Spinner } from "react-bootstrap";
import download from "downloadjs";
import jsPDF from "jspdf";
import ReactToPrint from "react-to-print";

import PaymentModal from "../modals/PaymentModal";
import NoteModal from "../modals/NoteModal";
import DoorRender from "./SaleDoorRender";
import { SalesContext } from "../../context/salesContext";
import { CustomersContext } from "../../context/customersContext";
import "./Sale.css";
import "../forms/Form.css";
import InsertDesigns from "../../data/insertDesigns.json";
import statusList from "../../data/statusList.json";
import sills from "../../data/sills.json";
import lockTypes from "../../data/lockTypes.json";
import hingeColours from "../../data/hingeColours.json";
import DoorStyles from "../../data/doorStyles.json";

export class ComponentToPrint extends React.PureComponent {
  render() {
    const {
      updatedSale,
      selectedSale,
      editForm,
      handleUpdateList,
      setUpdatedSale,
      setRecentActivities,
      handleUpdateCheckbox,
      removePaintItem,
      updating,
      paintItem,
      handleUpdatePaint,
      addPaintItem,
      addingPaintItemError,
      createPaintList,
    } = this.props;
    return (
      <div className="Sale-paintlist-printable">
        <div className="Sale-paintlist-header">
          <div className="Sale-paintlist-header-company-details">
            <h3 className="title bold">Summit Doors </h3>
            <p>
              <span className="bold">Address:</span> 737 Fenmar Drive, North
              York, ON
            </p>
            <p>
              {" "}
              <span className="bold">Fax:</span> 416-391-6041
            </p>
            <p>
              <span className="bold">Office:</span> 416-742-9188
            </p>
            <p>
              <span className="bold">Mobile:</span> 647-296-2825
            </p>
          </div>

          <div className="Sale-paintlist-header-right-container">
            <p style={{ fontWeight: "normal" }}>
              <span className="bold">Purchase Order: </span>
              {updatedSale.po}
            </p>

            <div className="Sale-paintList-date">
              <label
                htmlFor="shipTo"
                style={{ marginRight: "0.5rem", marginBottom: 0 }}
              >
                Ship To:
              </label>

              {editForm ? (
                <select
                  style={{ width: "30%" }}
                  className="Sale-paintform-input"
                  value={updatedSale.paintList.shipTo}
                  onChange={(event) =>
                    handleUpdateList(
                      setUpdatedSale,
                      "shipTo",
                      event.target.value,
                      "paintList"
                    )
                  }
                  onBlur={setRecentActivities}
                  disabled={!editForm}
                >
                  <option value="Roy (Quality Cote)">Roy (Quality Cote)</option>
                  <option value="Gary (UV Coating)">Gary (UV Coating)</option>
                  <option value="Ebi (Vynal Colour Land Inc)">
                    Ebi (Vynal Colour Land Inc)
                  </option>
                  <option value="Mike (Monarch)">Mike (Monarch)</option>
                </select>
              ) : (
                <span>
                  <p style={{ margin: "none" }}>
                    {updatedSale.paintList.shipTo}
                  </p>
                </span>
              )}
            </div>

            {updatedSale.paintList &&
              updatedSale.paintList.items &&
              updatedSale.paintList.items.length > 0 && (
                <div>
                  <div className="Sale-paintList-date">
                    <label
                      htmlFor="createdDate"
                      style={{ marginRight: "0.5rem", marginBottom: 0 }}
                    >
                      Date:
                    </label>

                    {editForm ? (
                      <input
                        style={{ width: "30%" }}
                        className="Sale-paintform-input"
                        type="date"
                        value={Moment(updatedSale.paintList.createdDate)
                          .utc()
                          .format("YYYY-MM-DD")}
                        onChange={(event) =>
                          handleUpdateList(
                            setUpdatedSale,
                            "createdDate",
                            event.target.value,
                            "paintListDate"
                          )
                        }
                        onBlur={setRecentActivities}
                        disabled={!editForm}
                      />
                    ) : (
                      <span>
                        <p style={{ margin: "none" }}>
                          {Moment(updatedSale.paintList.createdDate).format(
                            "LL"
                          )}
                        </p>
                      </span>
                    )}
                  </div>

                  <hr />
                  <div className="Sale-paintList-date">
                    <label
                      htmlFor="pickedUp"
                      style={{ marginRight: "0.5rem", marginBottom: 0 }}
                    >
                      Picked Up:
                    </label>

                    <input
                      id="pickedUp"
                      name="pickedUp"
                      style={{
                        width: "10%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="Sale-paintform-input"
                      type="checkbox"
                      checked={updatedSale.paintList.pickedUp}
                      onChange={handleUpdateCheckbox}
                      onBlur={setRecentActivities}
                    />
                  </div>

                  <div className="Sale-paintList-date">
                    <label
                      htmlFor="pickedUp"
                      style={{ marginRight: "0.5rem", marginBottom: 0 }}
                    >
                      Picked Up Date:
                    </label>

                    {editForm ? (
                      <input
                        id="pickedUpDate"
                        name="pickedUpDate"
                        style={{
                          width: "30%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        className="Sale-paintform-input"
                        type="date"
                        value={Moment(updatedSale.paintList.pickedUpDate)
                          .utc()
                          .format("YYYY-MM-DD")}
                        onChange={(event) =>
                          handleUpdateList(
                            setUpdatedSale,
                            "pickedUp",
                            event.target.value,
                            "paintListDate"
                          )
                        }
                        onBlur={setRecentActivities}
                      />
                    ) : (
                      <span>
                        {updatedSale.paintList.pickedUpDate !== "" && (
                          <p>
                            {Moment(updatedSale.paintList.pickedUDate).format(
                              "LL"
                            )}
                          </p>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              )}
          </div>
        </div>

        <div className="Sale-paintform-items">
          <p>
            Items:{" "}
            {updatedSale.paintList &&
              updatedSale.paintList.items &&
              updatedSale.paintList.items.length > 0 &&
              updatedSale.paintList.items.reduce((accum, item) => {
                if (item.quantity !== "") {
                  return accum + parseInt(item.quantity);
                } else {
                  return accum + 0;
                }
              }, 0)}
          </p>
          {updatedSale.paintList &&
            updatedSale.paintList.items &&
            updatedSale.paintList.items.length > 0 && (
              <div>
                <table className="Sale-table-list" style={{ width: "100%" }}>
                  <tr>
                    <th>Quantity</th>
                    <th>Item</th>
                    <th>Side</th>
                    <th>Colour</th>
                  </tr>
                  {updatedSale.paintList.items.map((item, index) => {
                    return (
                      <tr>
                        <td style={{ width: "8%" }}>
                          <input
                            className="Sale-paintform-input"
                            type="number"
                            value={item.quantity}
                            onChange={(event) =>
                              handleUpdateList(
                                setUpdatedSale,
                                "quantity",
                                event.target.value,
                                "paintListItem",
                                index
                              )
                            }
                            onBlur={setRecentActivities}
                            disabled={!editForm}
                          />
                        </td>
                        <td style={{ width: "40%" }}>
                          <input
                            className="Sale-paintform-input"
                            type="text"
                            value={item.item}
                            onChange={(event) =>
                              handleUpdateList(
                                setUpdatedSale,
                                "item",
                                event.target.value,
                                "paintListItem",
                                index
                              )
                            }
                            onBlur={setRecentActivities}
                            disabled={!editForm}
                          />
                        </td>
                        <td style={{ width: "15%" }}>
                          {editForm ? (
                            <select
                              className="Sale-paintform-input"
                              value={item.side}
                              onChange={(event) =>
                                handleUpdateList(
                                  setUpdatedSale,
                                  "side",
                                  event.target.value,
                                  "paintListItem",
                                  index
                                )
                              }
                              onBlur={setRecentActivities}
                              disabled={!editForm}
                            >
                              <option value=""></option>
                              <option value="Out">Out</option>
                              <option value="In/Out">In/Out</option>
                              <option value="In">In</option>
                            </select>
                          ) : (
                            <input
                              className="Sale-paintform-input"
                              type="text"
                              value={item.side}
                              onChange={(event) =>
                                handleUpdateList(
                                  setUpdatedSale,
                                  "item",
                                  event.target.value,
                                  "paintListItem",
                                  index
                                )
                              }
                              onBlur={setRecentActivities}
                              disabled={!editForm}
                            />
                          )}
                        </td>
                        <td style={{ width: "15%" }}>
                          <input
                            className="Sale-paintform-input"
                            type="text"
                            value={item.colour}
                            onChange={(event) =>
                              handleUpdateList(
                                setUpdatedSale,
                                "colour",
                                event.target.value,
                                "paintListItem",
                                index
                              )
                            }
                            onBlur={setRecentActivities}
                            disabled={!editForm}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </table>
                <br />

                {editForm && (
                  <button className="btn btn-danger" onClick={removePaintItem}>
                    {updating ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: " rgb(84, 163, 202)" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Remove Paint Form"
                    )}
                  </button>
                )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

const Sale = (props) => {
  const { selectedSale, setSelectedSale, openSaleModal } = props;
  const { customers } = useContext(CustomersContext);
  const { updating, action } = useContext(SalesContext);
  const [editForm, setEditForm] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);
  const [showNotesModal, setShowNotesModal] = useState(false);
  const [flattenPrevArray] = useState([]);
  const [flattenUpdatedArray] = useState([]);

  const [updatedSale, setUpdatedSale] = useState({
    orderDate: selectedSale.orderDate || "",
    customerId: selectedSale.customerId || "",
    contractor: selectedSale.contractor || "",
    po: selectedSale.po || "",
    product: selectedSale.product || "",
    productSpecification: selectedSale.productSpecification || {},
    paymentMethod: selectedSale.paymentMethod || "",
    cost: selectedSale.cost || "",
    labourCost: selectedSale.labourCost || "",
    salesPrice: selectedSale.salesPrice || "",
    workOrder: selectedSale.workOrder || {},
    expectedCompletionDate: selectedSale.expectedCompletionDate || "",
    completionDate: selectedSale.completionDate || "",
    notes: selectedSale.notes || [],
    payments: selectedSale.payments || [],
    recentActivities: selectedSale.recentActivities || [],
    files: selectedSale.files || [],
    paintList: selectedSale.paintList || {},
    partsList: selectedSale.partsList || [],
  });

  const [files, setFiles] = useState([]);
  const [uploadMessage, setUploadMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  // Upload files library
  const onDrop = useCallback((acceptedFiles) => {
    files.push(acceptedFiles);

    updatedSale.recentActivities.push(
      `${Moment().format("LLL")}: ${
        Moment().format("YYYY-MM-DD") + "_" + acceptedFiles[0].name
      } was added.`
    );
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const addFile = async (event) => {
    event.preventDefault();
    setUploadMessage("");

    try {
      if (files.length <= 0) {
        setUploadMessage("Failed");
        setTimeout(() => {
          setUploadMessage("");
        }, 2500);
      } else {
        setUploading(true);

        const formData = new FormData();

        files.map((file) => {
          updatedSale.files.push(
            Moment().format("YYYY-MM-DD") + "_" + file[0].name
          );
          formData.append(
            `name`,
            Moment().format("YYYY-MM-DD") + "_" + file[0].name
          );
          formData.append(`file`, file[0]);
        });

        await action.upload(formData);
        await action.updateSale(selectedSale._id, updatedSale);
        setFiles([]);
        setUploading(false);
        setUploadMessage("Successful");
        setTimeout(() => {
          setUploadMessage("");
        }, 2500);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const getFile = async (file) => {
    const res = await action.getFiles(file);
    const url = URL.createObjectURL(res.data);

    const reader = new FileReader(res.data);
    reader.onload = (r) => {
      console.log(r.target.result);
    };

    return reader.readAsDataURL(res.data);
  };

  const downloadFile = async (file) => {
    const res = await action.getFiles(file);

    download(res.data, file);
  };

  const removeFile = async (file, selectedIndex) => {
    const updatedFiles = updatedSale.files
      .slice(0)
      .reverse()
      .filter((updatedRecentActivity, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedSale.files = updatedFiles;
    await action.updateSale(selectedSale._id, updatedSale);

    await action.deleteFile(file);
  };

  const prevArray = () => {
    let prevProperties = [];
    flattenPrevArray.length = 0;

    const getPrevRecords = (obj) => {
      for (let property in obj) {
        if (property !== "recentActivities") {
          if (typeof obj[property] === "object") {
            prevProperties.push(property);
            getPrevRecords(obj[property]);
          } else {
            if (prevProperties.length > 0) {
              prevProperties.push(property);
              flattenPrevArray.push({ [prevProperties]: obj[property] });
              prevProperties.pop();
            } else {
              flattenPrevArray.push({ [property]: obj[property] });
            }
          }
        }
      }
      prevProperties.pop();
    };
    getPrevRecords(selectedSale);
  };

  const currArray = () => {
    let currProperties = [];
    flattenUpdatedArray.length = 0;

    const getCurrRecords = (obj) => {
      for (let property in obj) {
        if (property !== "recentActivities") {
          if (typeof obj[property] === "object") {
            currProperties.push(property);
            getCurrRecords(obj[property]);
          } else {
            if (currProperties.length > 0) {
              currProperties.push(property);
              flattenUpdatedArray.push({ [currProperties]: obj[property] });
              currProperties.pop();
            } else {
              flattenUpdatedArray.push({ [property]: obj[property] });
            }
          }
        }
      }
      currProperties.pop();
    };
    getCurrRecords(updatedSale);
  };

  const setRecentActivities = () => {
    prevArray();
    currArray();

    flattenPrevArray.map((prevArray) => {
      for (let prevProperties in prevArray) {
        flattenUpdatedArray.map((updatedArray) => {
          for (let updatedProperties in updatedArray) {
            if (
              prevProperties === updatedProperties &&
              prevArray[prevProperties] !== updatedArray[updatedProperties]
            ) {
              // push recent activities with date format
              if (
                prevProperties === "orderDate" ||
                prevProperties === "expectedCompletionDate"
              ) {
                if (
                  Moment(prevArray[prevProperties]).format("LL") !==
                  Moment(updatedArray[prevProperties]).format("LL")
                ) {
                  // Update if recent activites was not already added
                  if (
                    !updatedSale.recentActivities.includes(
                      `${Moment().format(
                        "LLL"
                      )}: ${prevProperties} was changed from '${Moment(
                        prevArray[prevProperties]
                      ).format("LL")}' to '${Moment(
                        updatedArray[updatedProperties]
                      ).format("LL")}'`
                    )
                  ) {
                    updatedSale.recentActivities.push(
                      `${Moment().format(
                        "LLL"
                      )}: ${prevProperties} was changed from '${Moment(
                        prevArray[prevProperties]
                      ).format("LL")}' to '${Moment(
                        updatedArray[updatedProperties]
                      ).format("LL")}'`
                    );
                  }
                }
                // Push recent activities with customer
              } else if (prevProperties === "customerId") {
                let prevCustomer = customers.filter(
                  (customer) => customer._id === prevArray[prevProperties]
                );

                let updatedCustomer = customers.filter(
                  (customer) => customer._id === updatedArray[updatedProperties]
                );
                // Update if recent activites was not already added
                if (
                  !updatedSale.recentActivities.includes(
                    `${Moment().format(
                      "LLL"
                    )}: ${prevProperties} was changed from '${
                      prevCustomer[0].firstName
                    }' ${prevCustomer[0].lastName} to '${
                      updatedCustomer[0].firstName
                    }' ${updatedCustomer[0].lastName}`
                  )
                ) {
                  updatedSale.recentActivities.push(
                    `${Moment().format(
                      "LLL"
                    )}: ${prevProperties} was changed from '${
                      prevCustomer[0].firstName
                    }' ${prevCustomer[0].lastName} to '${
                      updatedCustomer[0].firstName
                    }' ${updatedCustomer[0].lastName}`
                  );
                }
              } else {
                // Update if recent activites was not already added
                if (
                  !updatedSale.recentActivities.includes(
                    `${Moment().format(
                      "LLL"
                    )}: ${prevProperties} was changed from '${
                      prevArray[prevProperties]
                    }' to '${updatedArray[updatedProperties]}'`
                  )
                ) {
                  updatedSale.recentActivities.push(
                    `${Moment().format(
                      "LLL"
                    )}: ${prevProperties} was changed from '${
                      prevArray[prevProperties]
                    }' to '${updatedArray[updatedProperties]}'`
                  );
                }
              }
            }
          }
        });
      }
    });
  };

  // PARTS LIST
  const [partsItem, setPartsItem] = useState({
    dateOrdered: Moment().utc().format("YYYY-MM-DD"),
    quantity: "",
    item: "",
    supplier: "",
    note: "",
    itemReceived: "",
    dateReceived: "",
  });

  const [addingPartsItemError, setAddingPartsItemError] = useState(false);

  const handleUpdateParts = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setPartsItem({
      ...partsItem,
      [name]: value,
    });
  };

  const addPartsItem = async (event) => {
    event.preventDefault();

    try {
      if (partsItem.quantity === "") {
        setAddingPartsItemError(true);
      } else {
        setAddingPartsItemError(false);

        updatedSale.partsList.push(partsItem);

        await action.updateSale(selectedSale._id, updatedSale);
        setPaintItem({
          dateOrdered: Moment().utc().format("YYYY-MM-DD"),
          quantity: "",
          item: "",
          supplier: "",
          note: "",
          itemReceived: "",
          dateReceived: "",
        });
      }
    } catch (error) {
      console.log("Error adding parts item: ", error);
    }
  };

  const removePartsItem = async (selectedIndex) => {
    await action.setUpdating(true);
    const updatedPartsList = updatedSale.partsList.filter(
      (note, index) => index !== selectedIndex
    );

    updatedSale.partsList = updatedPartsList;
    await action.setUpdating(false);
  };

  // PAINT LIST
  const [paintItem, setPaintItem] = useState({
    quantity: "",
    item: "",
    paintOut: "",
    paintIn: "",
    note: "",
  });

  const [addingPaintItemError, setAddingPaintItemError] = useState(false);

  // GENERATE PDF
  const componentRef = useRef();

  const createPaintList = async () => {
    const paintList = {
      createdDate: Moment().format("LLL"),
      shipTo: "",
      items: [
        {
          quantity: "",
          item: "Door",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Sidelights",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "EXEC. Panel",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Kick Panel",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Sweep Cover",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Sweep",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Lite Frame",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Astragal",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "EXT. Astragal",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Shoes",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Screens",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Screen Track",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "4-5/8 Jamb",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "6-5/8 Jamb",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "4-5/8 Post",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Boots",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: '2" Brickmould',
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Brickmouldd",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Flat Brickmould",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Patio Post",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Jamb EXT.",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Transoms",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Transom Trim",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Trim",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Burlap",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Casing",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Sill Cap",
          side: "",
          colour: "",
        },
        {
          quantity: "",
          item: "Touch Up",
          side: "",
          colour: "",
        },
      ],
      pickedUp: false,
      pickedUpDate: "",
      specialNotes: "",
      verifiedBy: "",
    };

    try {
      updatedSale.paintList = paintList;
      setEditForm(true);

      action.updateSale(selectedSale._id, updatedSale);
      console.log(updatedSale);
    } catch (error) {
      console.log("Error adding paint list: ", error);
    }
  };

  const handleUpdatePaint = (event) => {
    event.preventDefault();
    const { name, value } = event.target;

    setPaintItem({
      ...paintItem,
      [name]: value,
    });
  };

  const addPaintItem = async (event) => {
    event.preventDefault();

    try {
      if (paintItem.quantity === "") {
        setAddingPaintItemError(true);
      } else {
        setAddingPaintItemError(false);
        if (paintItem.paintOut === "") {
          paintItem.paintOut =
            updatedSale.productSpecification.doorLeft.paintOut;
        }

        if (paintItem.paintIn === "") {
          paintItem.paintIn = updatedSale.productSpecification.doorLeft.paintIn;
        }

        updatedSale.paintList.items.push(paintItem);

        await action.updateSale(selectedSale._id, updatedSale);
        setPaintItem({
          quantity: "",
          item: "",
          paintOut: "",
          paintIn: "",
          note: "",
        });
      }
    } catch (error) {
      console.log("Error adding paint item: ", error);
    }
  };

  const removePaintItem = async () => {
    await action.setUpdating(true);

    updatedSale.paintList = {};
    await action.setUpdating(false);
  };

  const handleUpdate = (set, field, value, name) => {
    if (
      field === "po" ||
      field === "cost" ||
      field === "labourCost" ||
      field === "salesPrice" ||
      field === "orderDate" ||
      field === "customerId" ||
      field === "contractor" ||
      field === "product" ||
      field === "paymentMethod" ||
      field === "expectedCompletionDate" ||
      field === "completionDate"
    ) {
      if (field === "orderDate" || field === "expectedCompletionDate") {
        set((state) => ({
          ...state,
          [field]: Moment(value).format("LLLL"),
        }));
      } else {
        set((state) => ({
          ...state,
          [field]: value,
        }));
      }
    } else if (
      field === "doorSystem" ||
      field === "brickToBrick" ||
      field === "lock" ||
      field === "sill" ||
      field === "sillExtension"
    ) {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          [field]: value,
        },
      }));
    } else if (name === "jamb") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          jamb: {
            ...state.productSpecification.jamb,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorLeft") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorLeftInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            doorInsert: {
              ...state.productSpecification.doorLeft.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorLeftLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            lock: {
              ...state.productSpecification.doorLeft.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorLeftHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            hinge: {
              ...state.productSpecification.doorLeft.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddle") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorMiddleInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            doorInsert: {
              ...state.productSpecification.doorMiddle.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddleLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            lock: {
              ...state.productSpecification.doorMiddle.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddleHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            hinge: {
              ...state.productSpecification.doorMiddle.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorRightInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            doorInsert: {
              ...state.productSpecification.doorRight.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRightLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            lock: {
              ...state.productSpecification.doorRight.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRightHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            hinge: {
              ...state.productSpecification.doorRight.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (field === "frameSizeHeight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          frameSize: {
            ...state.productSpecification.frameSize,
            height: value,
          },
        },
      }));
    } else if (field === "frameSizeWidth") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          frameSize: {
            ...state.productSpecification.frameSize,
            width: value,
          },
        },
      }));
    } else if (name === "sidelightLeft") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          sidelightLeft: {
            ...state.productSpecification.sidelightLeft,
            [field]: value,
          },
        },
      }));
    } else if (name === "sidelightRight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          sidelightRight: {
            ...state.productSpecification.sidelightRight,
            [field]: value,
          },
        },
      }));
    } else if (name === "transom") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          transom: {
            ...state.productSpecification.transom,
            [field]: value,
          },
        },
      }));
    } else if (field === "installationRequired" || field === "status") {
      // time stamp completion date if status equals completed
      if (field === "status") {
        if (value === "Completed") {
          setUpdatedSale({
            ...updatedSale,
            completionDate: Moment().format("LL"),
          });
        } else if (value !== "Completed") {
          setUpdatedSale({
            ...updatedSale,
            completionDate: "",
          });
        }
      }

      set((state) => ({
        ...state,
        workOrder: {
          ...state.workOrder,
          [field]: value,
        },
      }));
    }
  };

  const handleUpdateList = (set, field, value, name, currentIndex) => {
    if (name === "paintList") {
      set((state) => ({
        ...state,
        paintList: {
          ...state.paintList,

          [field]: value,
        },
      }));
    } else if (name === "paintListItem") {
      if (field === "quantity") {
        if (updatedSale.paintList.items[currentIndex].colour === "") {
          updatedSale.paintList.items[currentIndex].colour =
            updatedSale.productSpecification.doorLeft.paintOut;
        }
      }
      set((state) => ({
        ...state,
        paintList: {
          ...state.paintList,
          items: state.paintList.items.map((item, index) =>
            index === currentIndex ? { ...item, [field]: value } : item
          ),
        },
      }));
    } else if (name === "paintListDate") {
      set((state) => ({
        ...state,
        paintList: {
          ...state.paintList,

          [field]: Moment(value).format("LLLL"),
        },
      }));
    } else if (name === "partsList") {
      set((state) => ({
        ...state,
        partsList: state.partsList.map((item, index) =>
          index === currentIndex ? { ...item, [field]: value } : item
        ),
      }));
    } else if (name === "partsListDate") {
      set((state) => ({
        ...state,
        partsList: state.partsList.map((item, index) =>
          index === currentIndex
            ? { ...item, [field]: Moment(value).format("LLLL") }
            : item
        ),
      }));
    }
  };

  const handleUpdateCheckbox = async (event) => {
    try {
      const { name, checked } = event.target;

      if (name === "pickedUp") {
        updatedSale.paintList.pickedUp = checked;
        if (checked) {
          if (updatedSale.paintList.pickedUpDate === "") {
            updatedSale.paintList.pickedUpDate = Moment();
          }
        } else {
          updatedSale.paintList.pickedUpDate = "";
        }
      } else if (name === "itemReceived") {
        updatedSale.partsList.itemReceived = checked;
        if (checked) {
          if (updatedSale.partsList.dateReceived === "") {
            updatedSale.partsList.dateReceived = Moment();
          }
        } else {
          updatedSale.partsList.dateReceived = "";
        }
      }

      await action.updateSale(selectedSale._id, updatedSale);
    } catch (error) {
      console.log("Error updating drop off field: ", error);
    }
  };

  const handleUpdatePartsListCheckbox = async (event, index) => {
    try {
      const { name, checked } = event.target;

      if (name === "itemReceived") {
        updatedSale.partsList[index].itemReceived = checked;
        if (checked) {
          if (updatedSale.partsList[index].dateReceived === "") {
            updatedSale.partsList[index].dateReceived = Moment();
          }
        } else {
          updatedSale.partsList[index].dateReceived = "";
        }
      }

      await action.updateSale(selectedSale._id, updatedSale);
    } catch (error) {
      console.log("Error updating drop off field: ", error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await action.updateSale(selectedSale._id, updatedSale);
      setEditForm(false);
      openSaleModal(selectedSale._id);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const inputField = (labelName, inputName, value, type, disable, name) => {
    return (
      <div className="Sale-form-input-row">
        <label htmlFor={inputName}>{labelName}</label>
        {(inputName === "cost" ||
          inputName === "labourCost" ||
          inputName === "salesPrice") && <span className="currency">$</span>}
        <input
          className="Sale-form-input"
          type={type}
          id={inputName}
          name={inputName}
          value={value}
          onChange={(event) =>
            handleUpdate(setUpdatedSale, inputName, event.target.value, name)
          }
          onBlur={setRecentActivities}
          disabled={!disable}
        />
      </div>
    );
  };

  const addNote = (note) => {
    updatedSale.notes.push(note);

    action.updateSale(selectedSale._id, updatedSale);
  };

  const removeNote = (selectedIndex) => {
    const updatedNotes = updatedSale.notes
      .slice(0)
      .reverse()
      .filter((note, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedSale.notes = updatedNotes;
  };

  const removeRecentActivity = (selectedIndex) => {
    const updatedRecentActivities = updatedSale.recentActivities
      .slice(0)
      .reverse()
      .filter((updatedRecentActivity, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    updatedSale.recentActivities = updatedRecentActivities;
  };

  const toggleButton = () => {
    if (!editForm) {
      return (
        <div className="Sale-edit-btn">
          <button className="btn btn-info edit" onClick={toggleForm}>
            <i className="fas fa-edit"></i>
          </button>
        </div>
      );
    } else {
      return (
        <div className="Sale-edit-btn">
          <button className="btn btn-danger cancel" onClick={toggleForm}>
            Cancel
          </button>
          <button className="btn btn-info update" onClick={handleSubmit}>
            Update
          </button>
        </div>
      );
    }
  };

  const toggleForm = (event) => {
    event.preventDefault();
    setEditForm(!editForm);

    if (event.target.innerHTML === "Cancel") {
      setUpdatedSale({
        orderDate: Moment(selectedSale.orderDate).format("YYYY-MM-DD"),
        contractor: selectedSale.contractor,
        customerId: selectedSale.customerId,
        po: selectedSale.po,
        product: selectedSale.product,
        productSpecification: selectedSale.productSpecification,
        paymentMethod: selectedSale.paymentMethod,
        cost: selectedSale.cost,
        labourCost: selectedSale.labourCost,
        salesPrice: selectedSale.salesPrice,
        payments: selectedSale.payments,
        workOrder: selectedSale.workOrder,
        expectedCompletionDate: Moment(
          selectedSale.expectedCompletionDate
        ).format("YYYY-MM-DD"),
        completionDate: selectedSale.completionDate,
        notes: selectedSale.notes,
        recentActivities: selectedSale.recentActivities,
        files: updatedSale.files,
        paintList: updatedSale.paintList,
        partsList: selectedSale.partsList,
      });
    }
  };

  const totalPayments = () => {
    let totalPayment = 0;
    updatedSale.payments
      .slice(0)
      .reverse()
      .map((payment) => {
        totalPayment += parseInt(payment.amount);
      });

    totalPayment = updatedSale.salesPrice - totalPayment;

    let style;
    if (totalPayment < 0) {
      style = {
        color: "green",
      };
    } else if (totalPayment > 0) {
      style = {
        color: "red",
      };
    } else {
      style = {
        color: "gray",
      };
    }

    return <p style={style}>${totalPayment}</p>;
  };

  const addPayment = (payment) => {
    updatedSale.payments.push(payment);

    action.updateSale(selectedSale._id, updatedSale);
  };

  const removePayment = (selectedIndex) => {
    const updatedPayments = updatedSale.payments.filter(
      (payment, index) => index !== selectedIndex
    );

    updatedSale.payments = updatedPayments;
  };

  return (
    <div className="Sale">
      {toggleButton()}
      <div className="Sale-section">
        <div className="Sale-top-left-container">
          <div style={{ width: "100%" }}>
            <form>
              <h5>SALES INFORMATION</h5>
              {inputField(
                "Order Date",
                "orderDate",
                Moment(updatedSale.orderDate).utc().format("YYYY-MM-DD"),
                "date",
                editForm
              )}
              <div className="Sale-form-input-row">
                <label htmlFor="contractor">Contractor</label>
                <select
                  className="Sale-form-input"
                  id="contractor"
                  name="contractor"
                  value={updatedSale.contractor}
                  onChange={(event) =>
                    handleUpdate(
                      setUpdatedSale,
                      "contractor",
                      event.target.value
                    )
                  }
                  onBlur={setRecentActivities}
                  disabled={!editForm}
                >
                  <option value=""></option>
                  {customers.map((customer, index) => {
                    if (customer.contractor === "Yes") {
                      return (
                        <option
                          key={index}
                          value={`${customer.firstName} ${customer.lastName}`}
                        >
                          {customer.firstName} {customer.lastName}
                        </option>
                      );
                    }
                  })}
                </select>
              </div>
              <div className="Sale-form-input-row">
                <label htmlFor="customerId">Customer</label>
                <select
                  className="Sale-form-input"
                  id="customerId"
                  name="customerId"
                  value={updatedSale.customerId}
                  onChange={(event) =>
                    handleUpdate(
                      setUpdatedSale,
                      "customerId",
                      event.target.value
                    )
                  }
                  onBlur={setRecentActivities}
                  disabled={!editForm}
                >
                  {customers.map((customer, index) => {
                    return (
                      <option key={index} value={customer._id}>
                        {customer.firstName} {customer.lastName}
                      </option>
                    );
                  })}
                </select>
              </div>
              {inputField("PO", "po", updatedSale.po, "text", editForm)}

              <div className="Sale-form-input-row">
                <label htmlFor="product">Product</label>
                <select
                  className="Sale-form-input"
                  id="product"
                  name="product"
                  value={updatedSale.product}
                  onChange={(event) =>
                    handleUpdate(setUpdatedSale, "product", event.target.value)
                  }
                  onBlur={setRecentActivities}
                  disabled={!editForm}
                >
                  <option value="Door">Door</option>
                  <option value="Glass">Glass</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              {inputField("Cost", "cost", updatedSale.cost, "number", editForm)}
              {inputField(
                "Labour Cost",
                "labourCost",
                updatedSale.labourCost,
                "number",
                editForm
              )}
              {inputField(
                "Sales Price",
                "salesPrice",
                updatedSale.salesPrice,
                "number",
                editForm
              )}

              <div className="Sale-form-input-row">
                <label htmlFor="paymentMethod">Payment Method</label>
                <select
                  className="Sale-form-input"
                  id="paymentMethod"
                  name="paymentMethod"
                  value={updatedSale.paymentMethod}
                  onChange={(event) =>
                    handleUpdate(
                      setUpdatedSale,
                      "paymentMethod",
                      event.target.value
                    )
                  }
                  onBlur={setRecentActivities}
                  disabled={!editForm}
                >
                  <option value="">Select</option>
                  <option value="Cash">Cash</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Debit">Debit</option>
                  <option value="E-Transfer">E-Transfer</option>
                </select>
              </div>

              <div className="Sale-form-input-row">
                <label htmlFor="createdDate">Created On:</label>
                <input
                  className="Sale-form-input"
                  id="createdDate"
                  name="createdDate"
                  value={Moment(selectedSale.createdDate).format("LLL")}
                  disabled={true}
                />
              </div>
              <div className="Sale-form-input-row">
                <label htmlFor="updatedDate">Last Updated</label>
                <input
                  className="Sale-form-input"
                  id="updatedDate"
                  name="updatedDate"
                  value={Moment(selectedSale.updatedDate).format("LLL")}
                  disabled={true}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="Sale-top-right-container">
          <Tabs defaultActiveKey="workOrder" id="uncontrolled-tab-example">
            <Tab eventKey="workOrder" title="Work Order">
              <div className="Sale-top-right-workOrder">
                <div>
                  <div className="Sale-form-input-row">
                    <label htmlFor="installationRequired">
                      Installation Required
                    </label>
                    <select
                      className="Sale-form-input"
                      id="installationRequired"
                      name="installationRequired"
                      value={updatedSale.workOrder.installationRequired}
                      onChange={(event) =>
                        handleUpdate(
                          setUpdatedSale,
                          "installationRequired",
                          event.target.value
                        )
                      }
                      onBlur={setRecentActivities}
                      disabled={!editForm}
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>

                  {inputField(
                    "Expected Completion Date",
                    "expectedCompletionDate",
                    Moment(updatedSale.expectedCompletionDate).format(
                      "YYYY-MM-DD"
                    ),
                    "date",
                    editForm
                  )}

                  <div className="Sale-form-input-row">
                    <label htmlFor="status">Status</label>
                    <select
                      className="Sale-form-input"
                      id="status"
                      name="status"
                      value={updatedSale.workOrder.status}
                      onChange={(event) =>
                        handleUpdate(
                          setUpdatedSale,
                          "status",
                          event.target.value
                        )
                      }
                      onBlur={setRecentActivities}
                      disabled={!editForm}
                    >
                      {statusList.map((status, index) => {
                        return (
                          <option key={index} value={status.status}>
                            {status.status}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {inputField(
                    "Completion Date",
                    "completionDate",
                    updatedSale.completionDate,
                    "text",
                    editForm
                  )}
                </div>
              </div>

              <div className="Sale-top-right-payment-container">
                {updatedSale.payments.length < 0 ? (
                  updatedSale.salesPrice
                ) : (
                  <h5 className="Sale-top-right-payment-container-balance">
                    BALANCE: {totalPayments()}
                  </h5>
                )}

                <div className="Sale-top-right-payment-container-button">
                  <button
                    className="btn btn-success"
                    onClick={() => setPaymentModalShow(true)}
                  >
                    Add Payment
                  </button>
                </div>

                {updatedSale.payments.map((payment, index) => {
                  return (
                    <div className="payment-card" key={index}>
                      <p>
                        {Moment(payment.date).format("LL")}: Received payment of
                        ${payment.amount}
                      </p>
                      {editForm && (
                        <button
                          className="close"
                          onClick={() => removePayment(index)}
                        >
                          x
                        </button>
                      )}
                    </div>
                  );
                })}

                <PaymentModal
                  paymentModalShow={paymentModalShow}
                  setPaymentModalShow={setPaymentModalShow}
                  addPayment={addPayment}
                />
              </div>
            </Tab>
            <Tab eventKey="notes" title="Notes">
              <div className="Sale-top-right-ra-section">
                <div className="Sale-top-right-payment-container-button">
                  <button
                    className="btn btn-success"
                    onClick={() => setShowNotesModal(true)}
                  >
                    Add Notes
                  </button>
                </div>

                {updatedSale.notes
                  .slice(0)
                  .reverse()
                  .map((note, index) => {
                    return (
                      <div className="note-card" key={index}>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            {Moment(note.date).format("LLL")}:
                          </span>{" "}
                          {note.description}
                        </p>
                        {editForm && (
                          <button
                            className="close"
                            onClick={() => removeNote(index)}
                          >
                            x
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>

              <NoteModal
                showNotesModal={showNotesModal}
                setShowNotesModal={setShowNotesModal}
                addNote={addNote}
              />
            </Tab>

            <Tab eventKey="Parts" title="Parts">
              <div className="Sale-top-right-ra-section">
                <div className="Sale-paintform-items">
                  <p>
                    Items:{" "}
                    {updatedSale.partsList &&
                      updatedSale.partsList &&
                      updatedSale.partsList.length > 0 &&
                      updatedSale.partsList.reduce(
                        (accum, item) => accum + item.quantity,
                        0
                      )}
                  </p>
                  {updatedSale.partsList &&
                    updatedSale.partsList &&
                    updatedSale.partsList.length > 0 && (
                      <table
                        className="Sale-table-list"
                        style={{ width: "100%" }}
                      >
                        <tr>
                          <th>Date Ordered</th>
                          <th>Quantity</th>
                          <th>Supplier</th>
                          <th>Item</th>
                          <th>Note</th>
                          <th>Item Received</th>
                          <th>Date Received</th>

                          <th></th>
                        </tr>
                        {updatedSale.partsList.map((item, index) => {
                          return (
                            <tr>
                              <td style={{ width: "8%" }}>
                                <input
                                  className="Sale-paintform-input"
                                  type="date"
                                  value={item.dateOrdered}
                                  onChange={(event) =>
                                    handleUpdateList(
                                      setUpdatedSale,
                                      "dateOrdered",
                                      event.target.value,
                                      "partsList",
                                      index
                                    )
                                  }
                                  onBlur={setRecentActivities}
                                  disabled={!editForm}
                                />
                              </td>
                              <td style={{ width: "8%" }}>
                                <input
                                  className="Sale-paintform-input"
                                  type="text"
                                  value={item.quantity}
                                  onChange={(event) =>
                                    handleUpdateList(
                                      setUpdatedSale,
                                      "quantity",
                                      event.target.value,
                                      "partsList",
                                      index
                                    )
                                  }
                                  onBlur={setRecentActivities}
                                  disabled={!editForm}
                                />
                              </td>
                              <td>
                                <input
                                  className="Sale-paintform-input"
                                  type="text"
                                  value={item.supplier}
                                  onChange={(event) =>
                                    handleUpdateList(
                                      setUpdatedSale,
                                      "supplier",
                                      event.target.value,
                                      "partsList",
                                      index
                                    )
                                  }
                                  onBlur={setRecentActivities}
                                  disabled={!editForm}
                                />
                              </td>
                              <td>
                                <input
                                  className="Sale-paintform-input"
                                  type="text"
                                  value={item.item}
                                  onChange={(event) =>
                                    handleUpdateList(
                                      setUpdatedSale,
                                      "item",
                                      event.target.value,
                                      "partsList",
                                      index
                                    )
                                  }
                                  onBlur={setRecentActivities}
                                  disabled={!editForm}
                                />
                              </td>
                              <td>
                                <input
                                  className="Sale-paintform-input"
                                  type="text"
                                  value={item.note}
                                  onChange={(event) =>
                                    handleUpdateList(
                                      setUpdatedSale,
                                      "note",
                                      event.target.value,
                                      "partsList",
                                      index
                                    )
                                  }
                                  onBlur={setRecentActivities}
                                  disabled={!editForm}
                                />
                              </td>
                              <td style={{ width: "5%" }}>
                                <input
                                  name="itemReceived"
                                  className="Sale-paintform-input"
                                  type="checkbox"
                                  checked={item.itemReceived}
                                  onChange={(event) =>
                                    handleUpdatePartsListCheckbox(event, index)
                                  }
                                  onBlur={setRecentActivities}
                                />
                              </td>
                              <td style={{ width: "10%" }}>
                                {editForm ? (
                                  <input
                                    name="dateReceived"
                                    className="Sale-paintform-input"
                                    type="date"
                                    value={Moment(item.dateReceived)
                                      .utc()
                                      .format("YYYY-MM-DD")}
                                    onChange={(event) =>
                                      handleUpdateList(
                                        setUpdatedSale,
                                        "dateReceived",
                                        event.target.value,
                                        "partsListDate",
                                        index
                                      )
                                    }
                                    onBlur={setRecentActivities}
                                    disabled={!editForm}
                                  />
                                ) : (
                                  <span>
                                    {item.dateReceived !== "" && (
                                      <p>
                                        {Moment(item.dateReceived).format("LL")}
                                      </p>
                                    )}
                                  </span>
                                )}
                              </td>
                              <td>
                                {editForm && (
                                  <button
                                    className="close"
                                    onClick={() => removePartsItem(index)}
                                  >
                                    {updating ? (
                                      <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: " rgb(84, 163, 202)" }}
                                      >
                                        <span className="sr-only">
                                          Loading...
                                        </span>
                                      </Spinner>
                                    ) : (
                                      <i class="fas fa-minus-circle"></i>
                                    )}
                                  </button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    )}
                </div>
                <hr />
                <div>
                  <form className="Sale-orderform">
                    <div
                      className="Sale-order-form-input"
                      style={{ width: "40%" }}
                    >
                      <label htmlFor="partsListDate">Order Date</label>
                      <input
                        id="partsListDate"
                        name="date"
                        type=" date"
                        value={partsItem.dateOrdered}
                        onChange={handleUpdateParts}
                      />
                    </div>
                    <div
                      className="Sale-order-form-input"
                      style={{ width: "10%" }}
                    >
                      <label htmlFor="partsListQuantity">Qty</label>
                      <input
                        id="partsListQuantity"
                        name="quantity"
                        type="number"
                        placeholder="Qty"
                        value={partsItem.quantity}
                        onChange={handleUpdateParts}
                      />
                    </div>
                    <div className="Sale-order-form-input">
                      <label htmlFor="partsListSupplier">Supplier</label>
                      <input
                        id="partsListSupplier"
                        name="supplier"
                        type="text"
                        value={partsItem.supplier}
                        onChange={handleUpdateParts}
                      />
                    </div>
                    <div
                      className="Sale-order-form-input"
                      style={{ width: "40%" }}
                    >
                      <label htmlFor="partsListItem">Item</label>
                      <input
                        id="partsListItem"
                        name="item"
                        type=" text"
                        placeholder="Item"
                        value={partsItem.item}
                        onChange={handleUpdateParts}
                      />
                    </div>

                    <div className="Sale-order-form-input">
                      <label htmlFor="partsListNote">Note</label>
                      <input
                        id="partsListNote"
                        name="note"
                        type="text"
                        placeholder="Add Note"
                        value={partsItem.note}
                        onChange={handleUpdateParts}
                      />
                    </div>
                  </form>
                  <button className="btn standard-btn" onClick={addPartsItem}>
                    {updating ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: " rgb(84, 163, 202)" }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    ) : (
                      "Add"
                    )}
                  </button>
                  {addingPaintItemError && (
                    <p className="error">Please fill out form to add item!</p>
                  )}
                </div>
              </div>
            </Tab>
            <Tab eventKey="Paint" title="Paint">
              <div className="Sale-top-right-ra-section">
                <ReactToPrint
                  trigger={() => (
                    <button className="btn btn-success">Print</button>
                  )}
                  content={() => componentRef.current}
                />
                <ComponentToPrint
                  ref={componentRef}
                  updatedSale={updatedSale}
                  selectedSale={selectedSale}
                  editForm={editForm}
                  handleUpdateList={handleUpdateList}
                  setUpdatedSale={setUpdatedSale}
                  setRecentActivities={setRecentActivities}
                  handleUpdateCheckbox={handleUpdateCheckbox}
                  removePaintItem={removePaintItem}
                  updating={updating}
                  paintItem={paintItem}
                  handleUpdatePaint={handleUpdatePaint}
                  addPaintItem={addPaintItem}
                  addingPaintItemError={addingPaintItemError}
                  createPaintList={createPaintList}
                />

                <div>
                  {(!updatedSale.paintList.items ||
                    updatedSale.paintList.items.length <= 0) && (
                    <button
                      className="btn standard-btn"
                      onClick={createPaintList}
                    >
                      Create Paint List
                    </button>
                  )}
                </div>
              </div>
            </Tab>

            <Tab eventKey="Files" title="Files">
              <div {...getRootProps()} className="Sale-file-container">
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>

              {/* DISPLAY SUCCESS MESSAGE */}
              {uploadMessage === "Successful" && (
                <div className="">
                  <p style={{ color: "green" }}>File Successfully Added!</p>
                </div>
              )}
              {uploadMessage === "Failed" && (
                <div className="">
                  <p style={{ color: "red" }}>Please add a file.</p>
                </div>
              )}

              <div>
                {files.map((file) => {
                  return (
                    <div
                      style={{
                        width: "300px",
                        minHeight: "100px",
                        border: "2px solid #dddddd",
                        marginTop: "15px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontWeight: "bold",
                        color: "#cccccc",
                      }}
                    >
                      <p>{file[0].path}</p>
                    </div>
                  );
                })}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <button className="btn standard-btn" onClick={addFile}>
                  {uploading ? (
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: " rgb(84, 163, 202)" }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  ) : (
                    "Add"
                  )}
                </button>
              </div>

              <hr />
              <div>
                <h6 className="title">Attachments:</h6>
                {updatedSale.files
                  .slice(0)
                  .reverse()
                  .map((file, index) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div
                          key={index}
                          className="note-card"
                          style={{
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={() => downloadFile(file)}
                        >
                          <p>{file}</p>

                          {/* <img alt="Embedded Image" src={getFile(file)} /> */}
                        </div>

                        {editForm && (
                          <button
                            className="close"
                            style={{ width: "5%" }}
                            onClick={() => removeFile(file, index)}
                          >
                            <i class="fas fa-minus-circle"></i>
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Tab>

            <Tab eventKey="recentActivities" title="Recent Activities">
              <div className="Sale-top-right-ra-section">
                {updatedSale.recentActivities
                  .slice(0)
                  .reverse()
                  .map((recentActivity, index) => {
                    return (
                      <div className="note-card" key={index}>
                        <p>{recentActivity}</p>
                        {editForm && (
                          <button
                            className="close"
                            onClick={() => removeRecentActivity(index)}
                          >
                            x
                          </button>
                        )}
                      </div>
                    );
                  })}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>

      <div className="Sale-section">
        <form className="Sale-product-specifications">
          <h5>PRODUCT SPECIFICATIONS</h5>
          <Collapsible
            className="collapsible-section"
            trigger="Door System"
            open={true}
          >
            <div className="Sale-form-input-row">
              <label htmlFor="doorSystem">Door System</label>
              <select
                className="Sale-form-input"
                id="doorSystem"
                name="doorSystem"
                value={updatedSale.productSpecification.doorSystem}
                onChange={(event) =>
                  handleUpdate(setUpdatedSale, "doorSystem", event.target.value)
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="Single Door">Single Door</option>
                <option value="Double Door">Double Door</option>
                <option value="Triple Door">Triple Door</option>
              </select>
            </div>

            <hr />
            {inputField(
              "Brick To Brick",
              "brickToBrick",
              updatedSale.productSpecification.brickToBrick,
              "text",
              editForm
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Frame Size</h6>
            {inputField(
              "Width",
              "frameSizeWidth",
              updatedSale.productSpecification.frameSize.width,
              "number",
              editForm
            )}
            {inputField(
              "Height",
              "frameSizeHeight",
              updatedSale.productSpecification.frameSize.height,
              "number",
              editForm
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Jamb</h6>
            {inputField(
              "Size",
              "jambSize",
              updatedSale.productSpecification.jamb.jambSize,
              "number",
              editForm,
              "jamb"
            )}

            <div className="Sale-form-input-row">
              <label htmlFor="jambMaterial">Material</label>
              <select
                className="Sale-form-input"
                id="jambMaterial"
                name="jambMaterial"
                value={updatedSale.productSpecification.jamb.jambMaterial}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "jambMaterial",
                    event.target.value,
                    "jamb"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Composite Smooth">Composite Smooth</option>
                <option value="Composite Woodgrain">Composite Woodgrain</option>
                <option value="Vinyl Wrap">Vinyl Wrap</option>
                <option value="See Notes">See Notes</option>
              </select>
            </div>

            <hr />
            <div className="Sale-form-input-row">
              <label htmlFor="sill">Sill</label>
              <select
                className="Sale-form-input"
                id="sill"
                name="sill"
                value={updatedSale.productSpecification.sill}
                onChange={(event) =>
                  handleUpdate(setUpdatedSale, "sill", event.target.value)
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {sills.map((sill, index) => (
                  <option value={sill} key={index}>
                    {sill}
                  </option>
                ))}
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="sillExtension">Sill Extension</label>
              <select
                className="Sale-form-input"
                id="sillExtension"
                name="sillExtension"
                value={updatedSale.productSpecification.sillExtension}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "sillExtension",
                    event.target.value
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="1.5">1.5</option>
                <option value="2.5">2.5</option>
                <option value="3.5">3.5</option>
                <option value="4.5">4.5</option>
              </select>
            </div>
          </Collapsible>

          {/* Left Door */}
          <Collapsible
            className="collapsible-section"
            trigger="Door Left"
            open={true}
          >
            <h6 style={{ fontWeight: "bold" }}>Door</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorMaterial">Material</label>
              <select
                className="Sale-form-input"
                id="doorMaterial"
                name="doorMaterial"
                value={updatedSale.productSpecification.doorLeft.doorMaterial}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorMaterial",
                    event.target.value,
                    "doorLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="Steel">Steel</option>
                <option value="Fibreglass">Fibreglass</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Brand</label>
              <select
                className="Sale-form-input"
                id="doorBrand"
                name="doorBrand"
                value={updatedSale.productSpecification.doorLeft.doorBrand}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorBrand",
                    event.target.value,
                    "doorLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="">Select</option>
                {updatedSale.productSpecification.doorLeft.doorMaterial ===
                  "Steel" && <option value="Dusco">Dusco</option>}
                {updatedSale.productSpecification.doorLeft.doorMaterial ===
                  "Steel" && <option value="Novatech">Novatech</option>}
                {updatedSale.productSpecification.doorLeft.doorMaterial ===
                  "Steel" && <option value="Trutech">Trutech</option>}
                {updatedSale.productSpecification.doorLeft.doorMaterial ===
                  "Fibreglass" && <option value="Mennie">Mennie</option>}
                {updatedSale.productSpecification.doorLeft.doorMaterial ===
                  "Fibreglass" && (
                  <option value="Richersons">Richersons</option>
                )}
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Style</label>
              <select
                className="Sale-form-input"
                id="doorStyle"
                name="doorStyle"
                value={updatedSale.productSpecification.doorLeft.doorStyle}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorStyle",
                    event.target.value,
                    "doorLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                {updatedSale.productSpecification.doorLeft.doorBrand === "" && (
                  <option value="">Please Select Door Brand First</option>
                )}
                {updatedSale.productSpecification.doorLeft.doorBrand !== "" && (
                  <option value="">Select</option>
                )}
                {DoorStyles.map((doorStyle) => {
                  if (
                    updatedSale.productSpecification.doorLeft.doorBrand ===
                    doorStyle.brand
                  ) {
                    return (
                      <option value={doorStyle.style}>{doorStyle.style}</option>
                    );
                  }
                })}
              </select>
            </div>

            {inputField(
              "Width",
              "doorSlabWidth",
              updatedSale.productSpecification.doorLeft.doorSlabWidth,
              "number",
              editForm,
              "doorLeft"
            )}
            {inputField(
              "Height",
              "doorSlabHeight",
              updatedSale.productSpecification.doorLeft.doorSlabHeight,
              "number",
              editForm,
              "doorLeft"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Paint</h6>
            {inputField(
              "Out",
              "paintOut",
              updatedSale.productSpecification.doorLeft.paintOut,
              "text",
              editForm,
              "doorLeft"
            )}
            {inputField(
              "In",
              "paintIn",
              updatedSale.productSpecification.doorLeft.paintIn,
              "text",
              editForm,
              "doorLeft"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Door Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorInsert">Insert</label>
              <select
                className="Sale-form-input"
                id="doorInsert"
                name="doorInsert"
                value={
                  updatedSale.productSpecification.doorLeft.doorInsert
                    .doorInsert
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorInsert",
                    event.target.value,
                    "doorLeftInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="design">Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="design"
                name="design"
                value={
                  updatedSale.productSpecification.doorLeft.doorInsert.design
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "design",
                    event.target.value,
                    "doorLeftInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Position",
              "position",
              updatedSale.productSpecification.doorLeft.doorInsert.position,
              "text",
              editForm,
              "doorLeftInsert"
            )}

            {inputField(
              "Quantity",
              "quantity",
              updatedSale.productSpecification.doorLeft.doorInsert.quantity,
              "number",
              editForm,
              "doorLeftInsert"
            )}

            {inputField(
              "Width",
              "width",
              updatedSale.productSpecification.doorLeft.doorInsert.width,
              "number",
              editForm,
              "doorLeftInsert"
            )}
            {inputField(
              "Height",
              "height",
              updatedSale.productSpecification.doorLeft.doorInsert.height,
              "number",
              editForm,
              "doorLeftInsert"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Lock</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="lockType">Type</label>
              <select
                className="Sale-form-input"
                id="lockType"
                name="lockType"
                value={updatedSale.productSpecification.doorLeft.lock.lockType}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "lockType",
                    event.target.value,
                    "doorLeftLock"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {lockTypes.map((lockType, index) => (
                  <option key={index} value={lockType}>
                    {lockType}
                  </option>
                ))}
              </select>
            </div>

            {inputField(
              "Dist. From Top",
              "distanceFromTop",
              updatedSale.productSpecification.doorLeft.lock.distanceFromTop,
              "text",
              editForm,
              "doorLeftLock"
            )}

            {inputField(
              "Style",
              "lockStyle",
              updatedSale.productSpecification.doorLeft.lock.lockStyle,
              "text",
              editForm,
              "doorLeftLock"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Hinge</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="hingeColour">Colour</label>
              <select
                className="Sale-form-input"
                id="hingeColour"
                name="hingeColour"
                value={
                  updatedSale.productSpecification.doorLeft.hinge.hingeColour
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "hingeColour",
                    event.target.value,
                    "doorLeftHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {hingeColours.map((hingeColour, index) => (
                  <option key={index} value={hingeColour}>
                    {hingeColour}
                  </option>
                ))}
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="selfClosing">Self Closing</label>
              <select
                className="Sale-form-input"
                id="selfClosing"
                name="selfClosing"
                value={
                  updatedSale.productSpecification.doorLeft.hinge.selfClosing
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "selfClosing",
                    event.target.value,
                    "doorLeftHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="position">Position</label>
              <select
                className="Sale-form-input"
                id="position"
                name="position"
                value={updatedSale.productSpecification.doorLeft.hinge.position}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "position",
                    event.target.value,
                    "doorLeftHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
              </select>
            </div>

            <hr />

            <div className="Sale-form-input-row">
              <label htmlFor="open">Door Open</label>
              <select
                className="Sale-form-input"
                id="open"
                name="open"
                value={updatedSale.productSpecification.doorLeft.open}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "open",
                    event.target.value,
                    "doorLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </Collapsible>

          {/* Middle Door */}
          <Collapsible
            className="collapsible-section"
            trigger="Middle Door"
            open={
              updatedSale.productSpecification.doorSystem === "Double Door" ||
              updatedSale.productSpecification.doorSystem === "Triple Door"
                ? true
                : false
            }
          >
            <h6 style={{ fontWeight: "bold" }}>Door</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorMaterial">Material</label>
              <select
                className="Sale-form-input"
                id="doorMaterial"
                name="doorMaterial"
                value={updatedSale.productSpecification.doorMiddle.doorMaterial}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorMaterial",
                    event.target.value,
                    "doorMiddle"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="Steel">Steel</option>
                <option value="Fibreglass">Fibreglass</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Brand</label>
              <select
                className="Sale-form-input"
                id="doorBrand"
                name="doorBrand"
                value={updatedSale.productSpecification.doorMiddle.doorBrand}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorBrand",
                    event.target.value,
                    "doorMiddle"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="">Select</option>
                {updatedSale.productSpecification.doorMiddle.doorMaterial ===
                  "Steel" && <option value="Dusco">Dusco</option>}
                {updatedSale.productSpecification.doorMiddle.doorMaterial ===
                  "Steel" && <option value="Novatech">Novatech</option>}
                {updatedSale.productSpecification.doorMiddle.doorMaterial ===
                  "Fibreglass" && <option value="Mennie">Mennie</option>}
                {updatedSale.productSpecification.doorMiddle.doorMaterial ===
                  "Fibreglass" && (
                  <option value="Richersons">Richersons</option>
                )}
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Style</label>
              <select
                className="Sale-form-input"
                id="doorStyle"
                name="doorStyle"
                value={updatedSale.productSpecification.doorMiddle.doorStyle}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorStyle",
                    event.target.value,
                    "doorMiddle"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                {updatedSale.productSpecification.doorMiddle.doorBrand ===
                  "" && (
                  <option value="">Please Select Door Brand First</option>
                )}
                {updatedSale.productSpecification.doorMiddle.doorBrand !==
                  "" && <option value="">Select</option>}
                {DoorStyles.map((doorStyle) => {
                  if (
                    updatedSale.productSpecification.doorMiddle.doorBrand ===
                    doorStyle.brand
                  ) {
                    return (
                      <option value={doorStyle.style}>{doorStyle.style}</option>
                    );
                  }
                })}
              </select>
            </div>

            {inputField(
              "Width",
              "doorSlabWidth",
              updatedSale.productSpecification.doorMiddle.doorSlabWidth,
              "number",
              editForm,
              "doorMiddle"
            )}
            {inputField(
              "Height",
              "doorSlabHeight",
              updatedSale.productSpecification.doorMiddle.doorSlabHeight,
              "number",
              editForm,
              "doorMiddle"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Paint</h6>
            {inputField(
              "Out",
              "paintOut",
              updatedSale.productSpecification.doorMiddle.paintOut,
              "text",
              editForm,
              "doorMiddle"
            )}
            {inputField(
              "In",
              "paintIn",
              updatedSale.productSpecification.doorMiddle.paintIn,
              "text",
              editForm,
              "doorMiddle"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Door Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorInsert">Insert</label>
              <select
                className="Sale-form-input"
                id="doorInsert"
                name="doorInsert"
                value={
                  updatedSale.productSpecification.doorMiddle.doorInsert
                    .doorInsert
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorInsert",
                    event.target.value,
                    "doorMiddleInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="design">Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="design"
                name="design"
                value={
                  updatedSale.productSpecification.doorMiddle.doorInsert.design
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "design",
                    event.target.value,
                    "doorMiddleInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Position",
              "position",
              updatedSale.productSpecification.doorMiddle.doorInsert.position,
              "text",
              editForm,
              "doorMiddleInsert"
            )}

            {inputField(
              "Quantity",
              "quantity",
              updatedSale.productSpecification.doorMiddle.doorInsert.quantity,
              "number",
              editForm,
              "doorMiddleInsert"
            )}

            {inputField(
              "Width",
              "width",
              updatedSale.productSpecification.doorMiddle.doorInsert.width,
              "number",
              editForm,
              "doorMiddleInsert"
            )}
            {inputField(
              "Height",
              "height",
              updatedSale.productSpecification.doorMiddle.doorInsert.height,
              "number",
              editForm,
              "doorMiddleInsert"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Lock</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="lockType">Type</label>
              <select
                className="Sale-form-input"
                id="lockType"
                name="lockType"
                value={
                  updatedSale.productSpecification.doorMiddle.lock.lockType
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "lockType",
                    event.target.value,
                    "doorMiddleLock"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {lockTypes.map((lockType, index) => (
                  <option key={index} value={lockType}>
                    {lockType}
                  </option>
                ))}
              </select>
            </div>

            {inputField(
              "Dist. From Top",
              "distanceFromTop",
              updatedSale.productSpecification.doorMiddle.lock.distanceFromTop,
              "text",
              editForm,
              "doorMiddleLock"
            )}

            {inputField(
              "Style",
              "lockStyle",
              updatedSale.productSpecification.doorMiddle.lock.lockStyle,
              "text",
              editForm,
              "doorMiddleLock"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Hinge</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="hingeColour">Colour</label>
              <select
                className="Sale-form-input"
                id="hingeColour"
                name="hingeColour"
                value={
                  updatedSale.productSpecification.doorMiddle.hinge.hingeColour
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "hingeColour",
                    event.target.value,
                    "doorMiddleHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {hingeColours.map((hingeColour, index) => (
                  <option key={index} value={hingeColour}>
                    {hingeColour}
                  </option>
                ))}
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="selfClosing">Self Closing</label>
              <select
                className="Sale-form-input"
                id="selfClosing"
                name="selfClosing"
                value={
                  updatedSale.productSpecification.doorMiddle.hinge.selfClosing
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "selfClosing",
                    event.target.value,
                    "doorMiddleHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="position">Position</label>
              <select
                className="Sale-form-input"
                id="position"
                name="position"
                value={
                  updatedSale.productSpecification.doorMiddle.hinge.position
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "position",
                    event.target.value,
                    "doorMiddleHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
              </select>
            </div>

            <hr />

            <div className="Sale-form-input-row">
              <label htmlFor="open">Door Open</label>
              <select
                className="Sale-form-input"
                id="open"
                name="open"
                value={updatedSale.productSpecification.doorMiddle.open}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "open",
                    event.target.value,
                    "doorMiddle"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </Collapsible>

          {/* Right Door */}
          <Collapsible
            className="collapsible-section"
            trigger="Right Door"
            open={
              updatedSale.productSpecification.doorSystem === "Triple Door"
                ? true
                : false
            }
          >
            <h6 style={{ fontWeight: "bold" }}>Door</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorMaterial">Material</label>
              <select
                className="Sale-form-input"
                id="doorMaterial"
                name="doorMaterial"
                value={updatedSale.productSpecification.doorRight.doorMaterial}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorMaterial",
                    event.target.value,
                    "doorRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="Steel">Steel</option>
                <option value="Fibreglass">Fibreglass</option>
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Brand</label>
              <select
                className="Sale-form-input"
                id="doorBrand"
                name="doorBrand"
                value={updatedSale.productSpecification.doorRight.doorBrand}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorBrand",
                    event.target.value,
                    "doorRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="">Select</option>
                {updatedSale.productSpecification.doorRight.doorMaterial ===
                  "Steel" && <option value="Dusco">Dusco</option>}
                {updatedSale.productSpecification.doorRight.doorMaterial ===
                  "Steel" && <option value="Novatech">Novatech</option>}
                {updatedSale.productSpecification.doorRight.doorMaterial ===
                  "Fibreglass" && <option value="Mennie">Mennie</option>}
                {updatedSale.productSpecification.doorRight.doorMaterial ===
                  "Fibreglass" && (
                  <option value="Richersons">Richersons</option>
                )}
                <option value="Other">Other</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="doorBrand">Style</label>
              <select
                className="Sale-form-input"
                id="doorStyle"
                name="doorStyle"
                value={updatedSale.productSpecification.doorRight.doorStyle}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorStyle",
                    event.target.value,
                    "doorRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                {updatedSale.productSpecification.doorRight.doorBrand ===
                  "" && (
                  <option value="">Please Select Door Brand First</option>
                )}
                {updatedSale.productSpecification.doorRight.doorBrand !==
                  "" && <option value="">Select</option>}
                {DoorStyles.map((doorStyle) => {
                  if (
                    updatedSale.productSpecification.doorRight.doorBrand ===
                    doorStyle.brand
                  ) {
                    return (
                      <option value={doorStyle.style}>{doorStyle.style}</option>
                    );
                  }
                })}
              </select>
            </div>

            {inputField(
              "Width",
              "doorSlabWidth",
              updatedSale.productSpecification.doorRight.doorSlabWidth,
              "number",
              editForm,
              "doorRight"
            )}
            {inputField(
              "Height",
              "doorSlabHeight",
              updatedSale.productSpecification.doorRight.doorSlabHeight,
              "number",
              editForm,
              "doorRight"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Paint</h6>
            {inputField(
              "Out",
              "paintOut",
              updatedSale.productSpecification.doorRight.paintOut,
              "text",
              editForm,
              "doorRight"
            )}
            {inputField(
              "In",
              "paintIn",
              updatedSale.productSpecification.doorRight.paintIn,
              "text",
              editForm,
              "doorRight"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Door Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="doorInsert">Insert</label>
              <select
                className="Sale-form-input"
                id="doorInsert"
                name="doorInsert"
                value={
                  updatedSale.productSpecification.doorRight.doorInsert
                    .doorInsert
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "doorInsert",
                    event.target.value,
                    "doorRightInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="design">Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="design"
                name="design"
                value={
                  updatedSale.productSpecification.doorRight.doorInsert.design
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "design",
                    event.target.value,
                    "doorRightInsert"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Position",
              "position",
              updatedSale.productSpecification.doorRight.doorInsert.position,
              "text",
              editForm,
              "doorRightInsert"
            )}

            {inputField(
              "Quantity",
              "quantity",
              updatedSale.productSpecification.doorRight.doorInsert.quantity,
              "number",
              editForm,
              "doorRightInsert"
            )}

            {inputField(
              "Width",
              "width",
              updatedSale.productSpecification.doorRight.doorInsert.width,
              "number",
              editForm,
              "doorRightInsert"
            )}
            {inputField(
              "Height",
              "height",
              updatedSale.productSpecification.doorRight.doorInsert.height,
              "number",
              editForm,
              "doorRightInsert"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Lock</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="lockType">Type</label>
              <select
                className="Sale-form-input"
                id="lockType"
                name="lockType"
                value={updatedSale.productSpecification.doorRight.lock.lockType}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "lockType",
                    event.target.value,
                    "doorRightLock"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {lockTypes.map((lockType, index) => (
                  <option key={index} value={lockType}>
                    {lockType}
                  </option>
                ))}
              </select>
            </div>

            {inputField(
              "Dist. From Top",
              "distanceFromTop",
              updatedSale.productSpecification.doorRight.lock.distanceFromTop,
              "text",
              editForm,
              "doorRightLock"
            )}

            {inputField(
              "Style",
              "lockStyle",
              updatedSale.productSpecification.doorRight.lock.lockStyle,
              "text",
              editForm,
              "doorRightLock"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Hinge</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="hingeColour">Colour</label>
              <select
                className="Sale-form-input"
                id="hingeColour"
                name="hingeColour"
                value={
                  updatedSale.productSpecification.doorRight.hinge.hingeColour
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "hingeColour",
                    event.target.value,
                    "doorRightHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                {hingeColours.map((hingeColour, index) => (
                  <option key={index} value={hingeColour}>
                    {hingeColour}
                  </option>
                ))}
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="selfClosing">Self Closing</label>
              <select
                className="Sale-form-input"
                id="selfClosing"
                name="selfClosing"
                value={
                  updatedSale.productSpecification.doorRight.hinge.selfClosing
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "selfClosing",
                    event.target.value,
                    "doorRightHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="position">Position</label>
              <select
                className="Sale-form-input"
                id="position"
                name="position"
                value={
                  updatedSale.productSpecification.doorRight.hinge.position
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "position",
                    event.target.value,
                    "doorRightHinge"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Left">Left</option>
                <option value="Right">Right</option>
              </select>
            </div>

            <hr />
            <div className="Sale-form-input-row">
              <label htmlFor="open">Door Open</label>
              <select
                className="Sale-form-input"
                id="open"
                name="open"
                value={updatedSale.productSpecification.doorRight.open}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "open",
                    event.target.value,
                    "doorRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </select>
            </div>
          </Collapsible>

          {/* SIDELIGHT LEFT */}
          <Collapsible
            className="collapsible-section"
            trigger="Left Sidelight"
            open={
              updatedSale.productSpecification.sidelightLeft.sidelight === "Yes"
                ? true
                : false
            }
          >
            <div className="Sale-form-input-row">
              <label htmlFor="sidelightBox">Side Light</label>
              <select
                className="Sale-form-input"
                id="sidelight"
                name="sidelight"
                value={updatedSale.productSpecification.sidelightLeft.sidelight}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "sidelight",
                    event.target.value,
                    "sidelightLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            {inputField(
              "# Of Panels",
              "panel",
              updatedSale.productSpecification.sidelightLeft.panel,
              "number",
              editForm,
              "sidelightLeft"
            )}

            {inputField(
              "Panel 1 Width",
              "panelWidth",
              updatedSale.productSpecification.sidelightLeft.panelWidth,
              "number",
              editForm,
              "sidelightLeft"
            )}

            {inputField(
              "Panel 1 Height",
              "panelHeight",
              updatedSale.productSpecification.sidelightLeft.panelHeight,
              "number",
              editForm,
              "sidelightLeft"
            )}

            {inputField(
              "Panel 2 Width",
              "panelWidth2",
              updatedSale.productSpecification.sidelightLeft.panelWidth2,
              "number",
              editForm,
              "sidelightLeft"
            )}

            {inputField(
              "Panel 2 Height",
              "panelHeight2",
              updatedSale.productSpecification.sidelightLeft.panelHeight2,
              "number",
              editForm,
              "sidelightLeft"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="sidelightLeftInsert">Glass Insert</label>
              <select
                className="Sale-form-input"
                id="sidelightLeftInsert"
                name="insert"
                value={updatedSale.productSpecification.sidelightLeft.insert}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "insert",
                    event.target.value,
                    "sidelightLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="insertDesign">Insert Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="insertDesign"
                name="insertDesign"
                value={
                  updatedSale.productSpecification.sidelightLeft.insertDesign
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "insertDesign",
                    event.target.value,
                    "sidelightLeft"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Width",
              "insertWidth",
              updatedSale.productSpecification.sidelightLeft.insertWidth,
              "number",
              editForm,
              "sidelightLeft"
            )}
            {inputField(
              "Height",
              "insertHeight",
              updatedSale.productSpecification.sidelightLeft.insertHeight,
              "number",
              editForm,
              "sidelightLeft"
            )}
          </Collapsible>

          {/* SIDELIGHT Right */}
          <Collapsible
            className="collapsible-section"
            trigger="Right Sidelight"
            open={
              updatedSale.productSpecification.sidelightRight.sidelight ===
              "Yes"
                ? true
                : false
            }
          >
            <div className="Sale-form-input-row">
              <label htmlFor="sidelightBox">Side Light</label>
              <select
                className="Sale-form-input"
                id="sidelight"
                name="sidelight"
                value={
                  updatedSale.productSpecification.sidelightRight.sidelight
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "sidelight",
                    event.target.value,
                    "sidelightRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            {inputField(
              "# Of Panels",
              "panel",
              updatedSale.productSpecification.sidelightRight.panel,
              "number",
              editForm,
              "sidelightRight"
            )}

            {inputField(
              "Panel 1 Width",
              "panelWidth",
              updatedSale.productSpecification.sidelightRight.panelWidth,
              "number",
              editForm,
              "sidelightRight"
            )}

            {inputField(
              "Panel 1 Height",
              "panelHeight",
              updatedSale.productSpecification.sidelightRight.panelHeight,
              "number",
              editForm,
              "sidelightRight"
            )}

            {inputField(
              "Panel 2 Width",
              "panelWidth2",
              updatedSale.productSpecification.sidelightRight.panelWidth2,
              "number",
              editForm,
              "sidelightRight"
            )}

            {inputField(
              "Panel 2 Height",
              "panelHeight2",
              updatedSale.productSpecification.sidelightRight.panelHeight2,
              "number",
              editForm,
              "sidelightRight"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="sidelightRightInsert">Glass Insert</label>
              <select
                className="Sale-form-input"
                id="sidelightRightInsert"
                name="insert"
                value={updatedSale.productSpecification.sidelightRight.insert}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "insert",
                    event.target.value,
                    "sidelightRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="insertDesign">Insert Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="insertDesign"
                name="insertDesign"
                value={
                  updatedSale.productSpecification.sidelightRight.insertDesign
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "insertDesign",
                    event.target.value,
                    "sidelightRight"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Width",
              "insertWidth",
              updatedSale.productSpecification.sidelightRight.insertWidth,
              "number",
              editForm,
              "sidelightRight"
            )}
            {inputField(
              "Height",
              "insertHeight",
              updatedSale.productSpecification.sidelightRight.insertHeight,
              "number",
              editForm,
              "sidelightRight"
            )}
          </Collapsible>

          {/* TRANSOM */}
          <Collapsible
            className="collapsible-section"
            trigger="Transom"
            open={
              updatedSale.productSpecification.transom.transomBox === "Yes"
                ? true
                : false
            }
          >
            <div className="Sale-form-input-row">
              <label htmlFor="transomBox">Transom</label>
              <select
                className="Sale-form-input"
                id="transomBox"
                name="transomBox"
                value={updatedSale.productSpecification.transom.transomBox}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "transomBox",
                    event.target.value,
                    "transom"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>

            <div className="Sale-form-input-row">
              <label htmlFor="transomType">Type</label>
              <select
                className="Sale-form-input"
                id="transomType"
                name="transomType"
                value={updatedSale.productSpecification.transom.transomType}
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "transomType",
                    event.target.value,
                    "transom"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              >
                <option value=""></option>
                <option value="Regular">Regular</option>
                <option value="Arc">Arc</option>
              </select>
            </div>

            {inputField(
              "Arc Height",
              "transomArcHeight",
              updatedSale.productSpecification.transom.transomArcHeight,
              "number",
              editForm,
              "transom"
            )}

            {inputField(
              "Arc Legs",
              "transomArcLegs",
              updatedSale.productSpecification.transom.transomArcLegs,
              "number",
              editForm,
              "transom"
            )}

            {inputField(
              "Transom Rows",
              "transomRow",
              updatedSale.productSpecification.transom.transomRow,
              "number",
              editForm,
              "transom"
            )}

            {inputField(
              "Transom Columns",
              "transomColumn",
              updatedSale.productSpecification.transom.transomColumn,
              "number",
              editForm,
              "transom"
            )}

            <hr />
            <h6 style={{ fontWeight: "bold" }}>Insert</h6>
            <div className="Sale-form-input-row">
              <label htmlFor="transomInsertDesign">Insert Design</label>
              <input
                className="Sale-form-input"
                type="text"
                list="insertDesigns"
                id="transomInsertDesigns"
                name="transomInsertDesign"
                value={
                  updatedSale.productSpecification.transom.transomInsertDesign
                }
                onChange={(event) =>
                  handleUpdate(
                    setUpdatedSale,
                    "transomInsertDesign",
                    event.target.value,
                    "transom"
                  )
                }
                onBlur={setRecentActivities}
                disabled={!editForm}
              />
              <datalist id="insertDesigns">
                {InsertDesigns.map((insertDesign) => {
                  return <option value={`${insertDesign.design}`} />;
                })}
              </datalist>
            </div>

            {inputField(
              "Width",
              "transomInsertWidth",
              updatedSale.productSpecification.transom.transomInsertWidth,
              "number",
              editForm,
              "transom"
            )}
            {inputField(
              "Height",
              "transomInsertHeight",
              updatedSale.productSpecification.transom.transomInsertHeight,
              "number",
              editForm,
              "transom"
            )}
          </Collapsible>
        </form>

        {selectedSale.product === "Door" && (
          <div className="Sale-bottom-door-render">
            <DoorRender
              selectedSale={selectedSale}
              setNewSale={setSelectedSale}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Sale;
