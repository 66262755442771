import React, { useContext, useState } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";

import { SalesContext } from "../../context/salesContext";
import Sale from "../details/Sale";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
    backgroundColor: "rgb(146, 200, 235)",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const SaleModal = (props) => {
  const classes = useStyles();

  const {
    open,
    setOpen,
    selectedSale,
    setSelectedSale,
    handleClose,
    openSaleModal,
  } = props;

  const { action } = useContext(SalesContext);
  const [deleteModal, setDeleteModal] = useState(false);

  const handleDelete = async () => {
    try {
      if (selectedSale) {
        action.deleteSale(selectedSale._id);
        setDeleteModal(false);
        setOpen(false);
      }
    } catch (error) {
      console.log("Error Deleting Customer: ", error);
    }
  };

  return (
    <div>
      {selectedSale && (
        <div>
          {/* Sale Detail Modal */}
          <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar className={classes.appBar}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Typography variant="h6" className={classes.title}>
                  {selectedSale.firstName} {selectedSale.lastName}
                </Typography>
                <Button
                  autoFocus
                  color="inherit"
                  onClick={() => setDeleteModal(true)}
                >
                  Delete
                </Button>
              </Toolbar>
            </AppBar>
            <List>
              <ListItem>
                <Sale
                  selectedSale={selectedSale}
                  setSelectedSale={setSelectedSale}
                  openSaleModal={openSaleModal}
                />
              </ListItem>
            </List>
          </Dialog>

          {/* Delete Warning Modal */}
          <div>
            <Dialog
              open={deleteModal}
              onClose={() => setDeleteModal(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Are you sure you want to delete this sale record?"}
              </DialogTitle>

              <DialogActions>
                <Button onClick={() => setDeleteModal(false)} color="primary">
                  Cancel
                </Button>
                <Button
                  className="btn btn-danger"
                  onClick={handleDelete}
                  color="secondary"
                  autoFocus
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      )}
    </div>
  );
};

export default SaleModal;
