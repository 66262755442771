import React, { useContext, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

import { SalesContext } from "../../context/salesContext";
import { CustomersContext } from "../../context/customersContext";
import SalesTable from "../tables/SalesTable";
import CustomerModal from "../modals/CustomerModal";
import SaleModal from "../modals/SaleModal";
import "./Sales.css";

const Sales = () => {
  const customerAction = useContext(CustomersContext).action;
  const { customers } = useContext(CustomersContext);
  const { action, salesList } = useContext(SalesContext);
  const [currentSalesList, setCurrentSalesList] = useState(salesList);
  const [search, setSearch] = useState("");

  useEffect(() => {
    action.getSalesList();
    if (search === "") {
      setCurrentSalesList(salesList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesList]);

  useEffect(() => {
    setCurrentSalesList(salesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!salesList]);

  const [openCustomer, setOpenCustomer] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [openSale, setOpenSale] = useState(false);
  const [selectedSale, setSelectedSale] = useState();

  const openCustomerModal = async (customerId) => {
    setOpenCustomer(true);

    const customer = await customerAction.getCustomer(customerId);
    setSelectedCustomer(customer);
  };

  const openSaleModal = async (saleId) => {
    setOpenSale(true);

    const sale = await action.getSale(saleId);
    setSelectedSale(sale);
  };

  const closeCustomerModal = () => {
    setOpenCustomer(false);
    setSelectedCustomer();
  };

  const closeSaleModal = () => {
    setOpenSale(false);
    setSelectedSale();
  };

  // SEARCH
  const handleSearch = (event) => {
    event.preventDefault();

    if (event.target.value === "") {
      setSearch("");
      setCurrentSalesList(salesList);
    } else {
      setSearch(event.target.value);
      // FIND CUSTOMER WITH SEARCH
      const searchedCustomers = customers.filter((customer) => {
        customer.fullName = `${customer.firstName} ${customer.lastName}`;

        return Object.entries(customer).some(
          (value) =>
            typeof value[1] === "string" &&
            (value[0] === "fullName" || value[0] === "mobile") &&
            value[1].toLowerCase().includes(event.target.value.toLowerCase())
        );
      });

      // array of customer Ids
      const customerIds = searchedCustomers.map((customers) => customers._id);

      // filter workOrder list with customers Id
      let searchInputCustomer = salesList.filter((workOrder) =>
        customerIds.includes(workOrder.customerId)
      );

      // Display Search results
      if (searchInputCustomer.length > 0 && search !== "") {
        setCurrentSalesList(searchInputCustomer);
      } else {
        // FIND PO WITH SEARCH
        const searchedPo = salesList.filter((workOrder) => {
          return Object.entries(workOrder).some(
            (value) =>
              typeof value[1] === "string" &&
              (value[0] === "po" || value[0] == "status") &&
              value[1].toLowerCase().includes(event.target.value.toLowerCase())
          );
        });

        // array of customer Ids
        const pos = searchedPo.map((pos) => pos.po);

        // filter workOrder list with customers Id
        let searchInputPo = salesList.filter((workOrder) =>
          pos.includes(workOrder.po)
        );

        setCurrentSalesList(searchInputPo);
      }
    }
  };

  const resetField = (event) => {
    event.preventDefault();
    if (event.target.value === "") {
      setCurrentSalesList(salesList);
    }
  };

  return (
    <div className="Sales">
      {salesList ? (
        <div>
          <div className="WorkOrders-searchbar-container">
            <input
              type="search"
              placeholder="Search PO #, status, customer or phone number"
              value={search}
              onChange={handleSearch}
              onFocus={resetField}
            />
          </div>
          {currentSalesList && (
            <SalesTable
              openCustomerModal={openCustomerModal}
              openSaleModal={openSaleModal}
              salesList={currentSalesList}
            />
          )}

          <CustomerModal
            selectedCustomer={selectedCustomer}
            open={openCustomer}
            setOpen={setOpenCustomer}
            handleClose={closeCustomerModal}
          />

          <SaleModal
            selectedSale={selectedSale}
            setSelectedSale={setSelectedSale}
            open={openSale}
            setOpen={setOpenSale}
            handleClose={closeSaleModal}
            openSaleModal={openSaleModal}
          />
        </div>
      ) : (
        <div className="loading">
          <Spinner
            animation="border"
            role="status"
            style={{ color: " rgb(84, 163, 202)" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default Sales;
