import React, { useContext, useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import Moment from "moment";

import { CustomersContext } from "../../context/customersContext";
import { SalesContext } from "../../context/salesContext";
import CustomerModal from "../modals/CustomerModal";
import SaleModal from "../modals/SaleModal";
import DoorRender from "./SaleDoorRender";
import statusList from "../../data/statusList.json";
import PaymentModal from "../modals/PaymentModal";
import "./WorkOrder.css";

const WorkOrder = (props) => {
  const { id } = props.match.params;
  const { customers } = useContext(CustomersContext);
  const { action, salesList } = useContext(SalesContext);
  const [workOrder, setWorkOrder] = useState(
    salesList.find((sale) => sale._id === id)
  );
  const [customer, setCustomer] = useState(
    customers.find((customer) => customer._id === workOrder.customerId)
  );
  const [currStatus, setCurrStatus] = useState(workOrder.workOrder.status);
  const [note, setNote] = useState({
    date: Moment().format("LLL"),
    description: "",
  });

  // MODALS
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [openSaleModal, setOpenSaleModal] = useState(false);
  const [paymentModalShow, setPaymentModalShow] = useState(false);

  // UPDATE SALES & CUSTOMERS LIST ON SALESLIST STATE CHANGE
  useEffect(() => {
    window.scrollTo(0, 0);

    setWorkOrder(salesList.find((sale) => sale._id === id));
    setCustomer(
      customers.find((customer) => customer._id === workOrder.customerId)
    );
  }, [salesList]);

  // UPDATE SALE WORKORDER STATUS
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Update recentActivities field
      workOrder.recentActivities.push(
        `${Moment().format("LLL")}: workOrder,status was changed from '${
          salesList.find((sale) => sale._id === id).workOrder.status
        }' to '${workOrder.workOrder.status}'`
      );

      await action.updateSale(workOrder._id, workOrder);
      setCurrStatus(workOrder.workOrder.status);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleCompleteButton = async (event) => {
    event.preventDefault();

    try {
      if (workOrder.workOrder.installationRequired === "Yes") {
        workOrder.workOrder.status = "Ready For Installation";
      } else {
        workOrder.workOrder.status = "Ready For Pick Up";
      }

      // Update recentActivities field
      workOrder.recentActivities.push(
        `${Moment().format("LLL")}: workOrder,status was changed from '${
          salesList.find((sale) => sale._id === id).workOrder.status
        }' to '${workOrder.workOrder.status}'`
      );

      await action.updateSale(workOrder._id, workOrder);
      setCurrStatus(workOrder.workOrder.status);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  //   SET SALE WORKORDER STATUS BACK TO CURR STATE
  const handleCancel = () => {
    setWorkOrder({
      ...workOrder,
      workOrder: {
        ...workOrder.workOrder,
        status: currStatus,
      },
    });
  };

  const handleUpdate = (event) => {
    const { value } = event.target;

    setNote({
      ...note,
      description: value,
    });
  };

  const addNote = (event) => {
    event.preventDefault();

    workOrder.notes.push(note);

    action.updateSale(id, workOrder);
    setNote({
      date: Moment().format("LLL"),
      description: "",
    });
  };

  const removeNote = (selectedIndex) => {
    const updatedNotes = workOrder.notes
      .slice(0)
      .reverse()
      .filter((note, index) => index !== selectedIndex)
      .slice(0)
      .reverse();

    workOrder.notes = updatedNotes;

    action.updateSale(id, workOrder);
    setWorkOrder(salesList.find((sale) => sale._id === id));
  };

  const addPayment = (payment) => {
    workOrder.payments.push(payment);

    action.updateSale(workOrder._id, workOrder);
  };

  const goBack = (event) => {
    event.preventDefault();

    props.history.push("/work-orders");
    action.getSalesList();
  };

  const changeStatus = (event) => {
    setWorkOrder({
      ...workOrder,
      workOrder: {
        ...workOrder.workOrder,
        status: event.target.innerHTML,
      },
    });
  };

  const readyColor = {
    color:
      workOrder.workOrder.status === "Completed"
        ? "green"
        : workOrder.workOrder.status.includes("Ready")
        ? "green"
        : "rgb(197, 197, 197)",
    fontWeight:
      workOrder.workOrder.status === "Completed"
        ? "bold"
        : workOrder.workOrder.status.includes("Ready")
        ? "bold"
        : "normal",
  };

  const activeColor = () => {
    if (workOrder.workOrder.status === "Processed") {
      return "#6b6b6b";
    } else if (workOrder.workOrder.status === "Pending Parts") {
      return "#ebe424";
    } else if (workOrder.workOrder.status === "Pending Paint") {
      return "orange";
    } else if (workOrder.workOrder.status === "Ship") {
      return "rgb(26, 153, 26)";
    } else if (workOrder.workOrder.status === "Prep") {
      return "rgb(22, 75, 158)";
    } else if (workOrder.workOrder.status === "Making Door") {
      return "rgb(28, 115, 255)";
    } else if (workOrder.workOrder.status === "Ready For Installation") {
      return "rgb(0, 207, 0)";
    } else if (workOrder.workOrder.status === "Ready For Pick Up") {
      return "rgb(0, 207, 0)";
    }
  };

  const borderColor = {
    borderLeft: "5px solid",
    borderRadius: "5px",
    borderColor: activeColor(),
  };

  const totalPayments = () => {
    let totalPayment = 0;
    workOrder.payments
      .slice(0)
      .reverse()
      .map((payment) => {
        totalPayment += parseInt(payment.amount);
      });

    totalPayment = workOrder.salesPrice - totalPayment;

    let style;
    if (totalPayment < 0) {
      style = {
        color: "green",
      };
    } else if (totalPayment > 0) {
      style = {
        color: "red",
      };
    } else {
      style = {
        color: "gray",
      };
    }

    return <p style={style}>${totalPayment}</p>;
  };

  return (
    <div className="WorkOrder">
      {salesList && customers ? (
        <div>
          <button
            className="WorkOrder-back-button btn btn-secondary"
            onClick={goBack}
          >
            Back
          </button>
          <div className="WorkOrder-container" style={borderColor}>
            <h5 className="title">Work Order Details</h5>
            <div className="WorkOrder-card-payment-section">
              {workOrder.payments.length < 0 ? (
                workOrder.salesPrice
              ) : (
                <h5 className="WorkOrder-card-balance">
                  BALANCE: {totalPayments()}
                </h5>
              )}

              <div className="WorkOrder-card-button">
                <button
                  className="btn btn-success"
                  onClick={() => setPaymentModalShow(true)}
                >
                  Add Payment
                </button>
              </div>

              <PaymentModal
                paymentModalShow={paymentModalShow}
                setPaymentModalShow={setPaymentModalShow}
                addPayment={addPayment}
              />
            </div>

            <div className="WorkOrder-card-title">
              {" "}
              <span>Status: </span>
            </div>
            <div className="WorkOrder-card-status-list">
              <ul>
                {statusList.map((status, index) => {
                  const currentStatus = () => {
                    if (workOrder.workOrder.status === status.status) {
                      if (workOrder.workOrder.status === "Processed") {
                        return "processed";
                      } else if (
                        workOrder.workOrder.status === "Pending Parts"
                      ) {
                        return "pending-parts";
                      } else if (workOrder.workOrder.status === "Prep") {
                        return "prep";
                      } else if (
                        workOrder.workOrder.status === "Pending Paint"
                      ) {
                        return "pending-paint";
                      } else if (workOrder.workOrder.status === "Ship") {
                        return "ship";
                      } else if (workOrder.workOrder.status === "Making Door") {
                        return "door";
                      } else if (
                        workOrder.workOrder.status === "Ready For Installation"
                      ) {
                        return "ready";
                      } else if (
                        workOrder.workOrder.status === "Ready For Pick Up"
                      ) {
                        return "ready";
                      } else if (workOrder.workOrder.status === "Completed") {
                        return "ready";
                      }
                    }
                  };

                  return (
                    <button key={index}>
                      <li
                        className={
                          workOrder.workOrder.status === status.status
                            ? `WorkOrder-active-${currentStatus()}`
                            : ""
                        }
                        onClick={changeStatus}
                      >
                        {status.status}
                      </li>
                    </button>
                  );
                })}
              </ul>
              {currStatus !== workOrder.workOrder.status && (
                <div className="WorkOrder-card-status-handle-buttons-container">
                  <h1>DO YOU WANT TO CONFIRM THE STATUS CHANGE?</h1>
                  <div className="WorkOrder-card-status-handle-buttons">
                    <button style={{ color: "red" }} onClick={handleCancel}>
                      <i className="fas fa-times"></i>
                    </button>
                    <button style={{ color: "green" }} onClick={handleSubmit}>
                      <i className="fas fa-check"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div className="WorkOrder-card-container-card">
              <div className="WorkOrder-card-details">
                <p>
                  <span>PO #: </span>
                  {workOrder.po}
                </p>
                <div
                  style={{
                    width: "100%",
                    height: "1px",
                    marginBottom: "1rem",
                    border: "1px solid #f6f6f6",
                  }}
                ></div>
                <p>
                  <span>Brick to Brick: </span>
                  {workOrder.productSpecification.brickToBrick}
                </p>
                <p>
                  <span>Frame Size: </span>
                  {workOrder.productSpecification.frameSize.width &&
                    `${action.getFraction(
                      workOrder.productSpecification.frameSize.width
                    )}" x${" "}
                  ${action.getFraction(
                    workOrder.productSpecification.frameSize.height
                  )}"`}
                </p>

                {/* color red if jamb is not standard size */}
                <p
                  style={{
                    color:
                      workOrder.productSpecification.jamb.jambSize !== "6.625"
                        ? "red"
                        : "black",
                  }}
                >
                  <span>Jamb: </span>
                  {workOrder.productSpecification.jamb.jambSize &&
                    `${action.getFraction(
                      workOrder.productSpecification.jamb.jambSize
                    )}"`}{" "}
                  - {workOrder.productSpecification.jamb.jambMaterial}
                </p>

                <p>
                  <span>Paint Out: </span>
                  {workOrder.productSpecification.doorLeft.paintOut}
                </p>
                <p>
                  <span>Paint In: </span>
                  {workOrder.productSpecification.doorLeft.paintIn}
                </p>

                {/* Door Left */}
                {workOrder.productSpecification.doorLeft.doorMaterial && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>
                    <h6 className="title">DOOR LEFT </h6>
                    <p>
                      {" "}
                      <span>Door Slab: </span>
                      {workOrder.productSpecification.doorLeft.doorMaterial !==
                        "" &&
                        `
                    ${workOrder.productSpecification.doorLeft.doorMaterial} -
                    ${workOrder.productSpecification.doorLeft.doorBrand}
                    ${workOrder.productSpecification.doorLeft.doorStyle} 
                    ${action.getFraction(
                      workOrder.productSpecification.doorLeft.doorSlabWidth
                    )}" x ${action.getFraction(
                          workOrder.productSpecification.doorLeft.doorSlabHeight
                        )}"
                    `}
                    </p>

                    <p>
                      <span>Lock: </span>
                      {workOrder.productSpecification.doorLeft.lock.lockType}
                      {workOrder.productSpecification.doorLeft.lock
                        .distanceFromTop &&
                        ` (${workOrder.productSpecification.doorLeft.lock.distanceFromTop} from Top)`}
                      {" - "}
                      {workOrder.productSpecification.doorLeft.lock.lockStyle}
                    </p>

                    <p>
                      <span>Hinge: </span>
                      {
                        workOrder.productSpecification.doorLeft.hinge
                          .hingeColour
                      }
                      {workOrder.productSpecification.doorLeft.hinge
                        .selfClosing === "Yes" && ", Self Closing"}
                    </p>
                  </div>
                )}

                {/* Door Middle */}
                {workOrder.productSpecification.doorMiddle.doorMaterial && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>
                    <h6 className="title">DOOR MIDDLE </h6>
                    <p>
                      <span>Door Slab: </span>
                      {workOrder.productSpecification.doorMiddle
                        .doorMaterial !== "" &&
                        `
                    ${workOrder.productSpecification.doorMiddle.doorMaterial} -
                    ${workOrder.productSpecification.doorMiddle.doorBrand}
                    ${workOrder.productSpecification.doorMiddle.doorStyle} 
                    ${action.getFraction(
                      workOrder.productSpecification.doorMiddle.doorSlabWidth
                    )}" x ${action.getFraction(
                          workOrder.productSpecification.doorMiddle
                            .doorSlabHeight
                        )}"
                    `}
                    </p>

                    <p>
                      <span>Lock: </span>
                      {workOrder.productSpecification.doorMiddle.lock.lockType}
                      {workOrder.productSpecification.doorMiddle.lock
                        .distanceFromTop &&
                        ` (${workOrder.productSpecification.doorMiddle.lock.distanceFromTop} from Top)`}
                      {" - "}
                      {workOrder.productSpecification.doorMiddle.lock.lockStyle}
                    </p>

                    <p>
                      <span>Hinge: </span>
                      {
                        workOrder.productSpecification.doorMiddle.hinge
                          .hingeColour
                      }
                      {workOrder.productSpecification.doorMiddle.hinge
                        .selfClosing === "Yes" && ", Self Closing"}
                    </p>
                  </div>
                )}

                {/* Door Right */}
                {workOrder.productSpecification.doorRight.doorMaterial && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>
                    <h6 className="title">DOOR RIGHT </h6>
                    <p>
                      <span>Door Slab: </span>
                      {workOrder.productSpecification.doorRight.doorMaterial !==
                        "" &&
                        `
                    ${workOrder.productSpecification.doorRight.doorMaterial} -
                    ${workOrder.productSpecification.doorRight.doorBrand}
                    ${workOrder.productSpecification.doorRight.doorStyle} 
                    ${action.getFraction(
                      workOrder.productSpecification.doorRight.doorSlabWidth
                    )}" x ${action.getFraction(
                          workOrder.productSpecification.doorRight
                            .doorSlabHeight
                        )}"
                    `}
                    </p>

                    <p>
                      <span>Lock: </span>
                      {workOrder.productSpecification.doorRight.lock.lockType}
                      {workOrder.productSpecification.doorRight.lock
                        .distanceFromTop &&
                        ` (${workOrder.productSpecification.doorRight.lock.distanceFromTop} from Top)`}
                      {" - "}
                      {workOrder.productSpecification.doorRight.lock.lockStyle}
                    </p>

                    <p>
                      <span>Hinge: </span>
                      {
                        workOrder.productSpecification.doorRight.hinge
                          .hingeColour
                      }
                      {workOrder.productSpecification.doorRight.hinge
                        .selfClosing === "Yes" && ", Self Closing"}
                    </p>
                  </div>
                )}

                {/* SIDELIGHT LEFT */}
                {workOrder.productSpecification.sidelightLeft.sidelight ===
                  "Yes" && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>

                    <h6 className="title">SIDELIGHT LEFT </h6>
                    <p>
                      <span>Panel: </span>
                      {`${workOrder.productSpecification.sidelightLeft.panel}`}
                    </p>
                    {workOrder.productSpecification.sidelightLeft
                      .panelWidth && (
                      <p>
                        <span>Panel 1:</span>
                        {workOrder.productSpecification.sidelightLeft
                          .panelWidth &&
                          `${action.getFraction(
                            workOrder.productSpecification.sidelightLeft
                              .panelWidth
                          )}" x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightLeft.panelHeight
                  )}"`}
                      </p>
                    )}

                    {workOrder.productSpecification.sidelightLeft
                      .panelWidth2 && (
                      <p>
                        <span>Panel 2: </span>
                        {workOrder.productSpecification.sidelightLeft
                          .panelWidth2 &&
                          `${action.getFraction(
                            workOrder.productSpecification.sidelightLeft
                              .panelWidth2
                          )}" x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightLeft.panelHeight2
                  )}"`}
                      </p>
                    )}

                    <p>
                      <span>Insert: </span>
                      {workOrder.productSpecification.sidelightLeft.insert ===
                        "Yes" &&
                        `
                  ${workOrder.productSpecification.sidelightLeft.insertDesign}
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightLeft.insertWidth
                  )}
                  " x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightLeft.insertHeight
                  )}
                  "
                  
                  `}
                    </p>
                  </div>
                )}

                {/* SIDELIGHT RIGHT */}
                {workOrder.productSpecification.sidelightRight.sidelight ===
                  "Yes" && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>

                    <h6 className="title">SIDELIGHT RIGHT </h6>
                    <p>
                      <span>Panel: </span>
                      {`${workOrder.productSpecification.sidelightRight.panel}`}
                    </p>
                    {workOrder.productSpecification.sidelightRight
                      .panelWidth && (
                      <p>
                        <span>Panel 1:</span>
                        {workOrder.productSpecification.sidelightRight
                          .panelWidth &&
                          `${action.getFraction(
                            workOrder.productSpecification.sidelightRight
                              .panelWidth
                          )}" x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightRight.panelHeight
                  )}"`}
                      </p>
                    )}

                    {workOrder.productSpecification.sidelightRight
                      .panelWidth2 && (
                      <p>
                        <span>Panel 2: </span>
                        {workOrder.productSpecification.sidelightRight
                          .panelWidth2 &&
                          `${action.getFraction(
                            workOrder.productSpecification.sidelightRight
                              .panelWidth2
                          )}" x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightRight.panelHeight2
                  )}"`}
                      </p>
                    )}

                    <p>
                      <span>Insert: </span>
                      {workOrder.productSpecification.sidelightRight.insert ===
                        "Yes" &&
                        `
                  ${workOrder.productSpecification.sidelightRight.insertDesign}
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightRight.insertWidth
                  )}
                  " x
                  ${action.getFraction(
                    workOrder.productSpecification.sidelightRight.insertHeight
                  )}
                  "
                  
                  `}
                    </p>
                  </div>
                )}

                {/* TRANSOM */}
                {workOrder.productSpecification.transom.transomBox ===
                  "Yes" && (
                  <div>
                    <div
                      style={{
                        width: "100%",
                        height: "1px",
                        marginBottom: "1rem",
                        border: "1px solid #f6f6f6",
                      }}
                    ></div>
                    <p>
                      <span>Type: </span>
                      {workOrder.productSpecification.transom.transomType}
                    </p>
                    <p>
                      {workOrder.productSpecification.transom.transomType ===
                        "Arc" && <span>Arc Height: </span>}

                      {workOrder.productSpecification.transom.transomType ===
                        "Arc" &&
                        `Arc Height ${workOrder.productSpecification.transom.transomArcHeight} `}
                    </p>
                    <p>
                      {workOrder.productSpecification.transom.transomType ===
                        "Arc" && <span>Arc Legs: </span>}
                      {workOrder.productSpecification.transom.transomType ===
                        "Arc" &&
                        `Arc Height ${workOrder.productSpecification.transom.transomArcLegs} `}
                    </p>
                    <p>
                      <span>Transom Insert: </span>
                      {workOrder.productSpecification.transom.transomBox ===
                        "Yes" &&
                        `
                  ${workOrder.productSpecification.transom.transomInsertDesign}
                  ${action.getFraction(
                    workOrder.productSpecification.transom.transomInsertWidth
                  )}
                  " x
                  ${action.getFraction(
                    workOrder.productSpecification.transom.transomInsertHeight
                  )}
                  "
                  
                  `}
                    </p>
                  </div>
                )}
              </div>

              <div className="WorkOrder-door-render">
                <DoorRender selectedSale={workOrder} />
              </div>
            </div>

            <div className="WorkOrder-buttons">
              <button
                className="btn btn-info"
                onClick={() => setOpenCustomerModal(true)}
              >
                Customer Details
              </button>
              <button
                className="btn btn-outline-info"
                onClick={() => setOpenSaleModal(true)}
              >
                Sale Details
              </button>
            </div>
          </div>

          <div className="WorkOrder-notes-section">
            <h5 className="title">Notes</h5>
            <ul></ul>
            <form className="WorkOrder-notes-form">
              <textarea
                value={note.description}
                onChange={handleUpdate}
                placeholder="Add a note..."
              />
              <div className="WorkOrder-notes-form-button">
                <button className="btn" onClick={addNote}>
                  Add
                </button>
              </div>
            </form>
            <div className="WorkOrder-notes-container">
              {workOrder.notes
                .slice(0)
                .reverse()
                .map((note, index) => {
                  return (
                    <div key={index} className="WorkOrder-notes-card">
                      <div>
                        <p style={{ fontWeight: "bold" }}>
                          {Moment(note.date).format("LLL")}
                        </p>
                        <p>{note.description}</p>
                      </div>
                      <div className="WorkOrder-delete-box">
                        <button
                          className="WorkOrder-delete-note"
                          onClick={() => removeNote(index)}
                        >
                          x
                        </button>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="WorkOrder-status" onClick={handleCompleteButton}>
            <i className="far fa-check-circle" style={readyColor}></i>
            <p>Door Ready</p>
          </div>

          <CustomerModal
            selectedCustomer={customer}
            open={openCustomerModal}
            setOpen={() => setOpenCustomerModal(true)}
            handleClose={() => setOpenCustomerModal(false)}
          />
          <SaleModal
            selectedSale={workOrder}
            setSelectedSale={setWorkOrder}
            open={openSaleModal}
            setOpen={() => setOpenSaleModal(true)}
            handleClose={() => setOpenSaleModal(false)}
          />
        </div>
      ) : (
        <div className="loading">
          <Spinner
            animation="border"
            role="status"
            style={{ color: " rgb(84, 163, 202)" }}
          >
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default WorkOrder;
