import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LoginContext } from "../../context/loginContext";
import "./Login.css";

const Login = () => {
  const { action, currentUser } = useContext(LoginContext);
  const history = useHistory();

  const [user, setUser] = useState({
    username: "test",
    password: "test",
  });

  const [validationError, setValidationError] = useState("");

  const handleUpdate = (event) => {
    event.preventDefault();

    const { name, value } = event.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (user.username === "" && user.password === "") {
      setValidationError("Please enter a username and password.");
    } else if (user.username !== "" && user.password === "") {
      setValidationError("Please enter a password.");
    } else if (user.username === "" && user.password !== "") {
      setValidationError("Please enter a username.");
    } else {
      const response = await action.signIn(user);
      console.log(response);

      if (response) {
        setValidationError("");
        history.push("/");
      } else {
        setValidationError(
          "The username or password you have entered does not match."
        );
      }
    }
  };

  return (
    <div className="Login">
      <div className="Login-card">
        <h1 className="title">Login</h1>

        <p className="title">
          Hello user! Click 'Sign In' to test the application!
        </p>
        {/* DISPLAY VALIDATION ERRORS */}
        {validationError !== "" && (
          <div>
            <p style={{ color: "red", fontWeight: "bold" }}>
              {validationError}
            </p>
          </div>
        )}

        <form className="Login-form">
          <input
            className="Login-form-input"
            type="text"
            name="username"
            value={user.username}
            onChange={handleUpdate}
            placeholder="Username"
          />
          <input
            className="Login-form-input"
            type="password"
            name="password"
            value={user.password}
            onChange={handleUpdate}
            placeholder="Password"
          />
          <button
            className="btn Login-form-button"
            style={{ backgroundColor: "rgb(84, 163, 202) !important" }}
            onClick={handleSubmit}
          >
            Sign In
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
