import React from "react";
import Collapsible from "react-collapsible";

import "../details/SaleDoorRender.css";
import lockTypes from "../../data/lockTypes.json";
import sills from "../../data/sills.json";
import hingeColours from "../../data/hingeColours.json";
import InsertDesigns from "../../data/insertDesigns.json";
import DoorStyles from "../../data/doorStyles.json";

const width10 = {
  width: "10%",
};

const width15 = {
  width: "15%",
};

const width20 = {
  width: "20%",
};

const width30 = {
  width: "30%",
};

const width40 = {
  width: "40%",
};

const FormSaleDoor = (props) => {
  const {
    newSale,
    setNewSale,
    renderDoorLeft,
    renderValidations,
    validationErrors,
  } = props;

  const handleUpdate = (set, field, value, name) => {
    if (field === "po" || field === "cost") {
      set((state) => ({
        ...state,
        [field]: value,
      }));
    } else if (
      field === "brickToBrick" ||
      field === "sill" ||
      field === "sillExtension" ||
      field === "doorSystem"
    ) {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          [field]: value,
        },
      }));
    } else if (name === "jamb") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          jamb: {
            ...state.productSpecification.jamb,
            [field]: value,
          },
        },
      }));
    } else if (field === "frameSizeHeight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          frameSize: {
            ...state.productSpecification.frameSize,
            height: value,
          },
        },
      }));
    } else if (field === "frameSizeWidth") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          frameSize: {
            ...state.productSpecification.frameSize,
            width: value,
          },
        },
      }));
    } else if (name === "doorLeftDoor") {
      if (
        newSale.productSpecification.sill !== "" &&
        newSale.productSpecification.frameSize.height !== ""
      ) {
        if (newSale.productSpecification.sill === "Self Draining Black") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 3.25;
        } else if (newSale.productSpecification.sill === "Self Draining Mill") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 3.25;
        } else if (newSale.productSpecification.sill === "Low Profile Mill") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 2.5;
        } else if (newSale.productSpecification.sill === "Adjustable Mill") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 2.75;
        } else if (newSale.productSpecification.sill === "Adjustable Black") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 2.75;
        } else if (newSale.productSpecification.sill === "Outswing") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 2.5;
        } else if (newSale.productSpecification.sill === "Handicap") {
          newSale.productSpecification.doorLeft.doorSlabHeight =
            newSale.productSpecification.frameSize.height - 1.875;
        }
      }

      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorLeft") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorLeftInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            doorInsert: {
              ...state.productSpecification.doorLeft.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorLeftLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            lock: {
              ...state.productSpecification.doorLeft.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorLeftHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorLeft: {
            ...state.productSpecification.doorLeft,
            hinge: {
              ...state.productSpecification.doorLeft.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddle") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorMiddleInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            doorInsert: {
              ...state.productSpecification.doorMiddle.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddleLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            lock: {
              ...state.productSpecification.doorMiddle.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorMiddleHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorMiddle: {
            ...state.productSpecification.doorMiddle,
            hinge: {
              ...state.productSpecification.doorMiddle.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            [field]: value,
          },
        },
      }));
    } else if (name === "doorRightInsert") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            doorInsert: {
              ...state.productSpecification.doorRight.doorInsert,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRightLock") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            lock: {
              ...state.productSpecification.doorRight.lock,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "doorRightHinge") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          doorRight: {
            ...state.productSpecification.doorRight,
            hinge: {
              ...state.productSpecification.doorRight.hinge,
              [field]: value,
            },
          },
        },
      }));
    } else if (name === "sidelightLeft") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          sidelightLeft: {
            ...state.productSpecification.sidelightLeft,
            [field]: value,
          },
        },
      }));
    } else if (name === "sidelightRight") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          sidelightRight: {
            ...state.productSpecification.sidelightRight,
            [field]: value,
          },
        },
      }));
    } else if (name === "transom") {
      set((state) => ({
        ...state,
        productSpecification: {
          ...state.productSpecification,
          transom: {
            ...state.productSpecification.transom,
            [field]: value,
          },
        },
      }));
    }
  };

  const copyDoorFields = (event) => {
    event.preventDefault();

    if (event.target.id === "doorMiddlebutton") {
      setNewSale({
        ...newSale,
        productSpecification: {
          ...newSale.productSpecification,
          doorMiddle: newSale.productSpecification.doorLeft,
        },
      });
    } else if (event.target.id === "doorRightbutton") {
      setNewSale({
        ...newSale,
        productSpecification: {
          ...newSale.productSpecification,
          doorRight: newSale.productSpecification.doorLeft,
        },
      });
    } else if (event.target.id === "sidelightbutton") {
      setNewSale({
        ...newSale,
        productSpecification: {
          ...newSale.productSpecification,
          sidelightRight: newSale.productSpecification.sidelightLeft,
        },
      });
    }
  };

  return (
    <div>
      <div className="Form-section-1-input" style={width20}>
        <div className="Form-input-container">
          <span className="Form-label">Door System</span>
          <select
            className="Form-input"
            name="doorSystem"
            value={newSale.productSpecification.doorSystem}
            onChange={(event) =>
              handleUpdate(setNewSale, "doorSystem", event.target.value)
            }
          >
            <option value="">Select</option>
            <option value="Single Door">Single Door</option>
            <option value="Double Door">Double Door</option>
            <option value="Triple Door">Triple Door</option>
          </select>
        </div>
      </div>

      {/* DOOR SYSTEM FIELDS */}
      {newSale.productSpecification.doorSystem !== "" && (
        <div>
          <h3 style={{ fontWeight: 900 }}>Door Specifications</h3>
          <div className="Form-section-1">
            <div className="Form-section-1-input" style={width10}>
              <div className="Form-input-container">
                <h6 style={{ fontWeight: 700 }}>Brick To Brick</h6>
                <input
                  className="Form-input"
                  type="text"
                  name="brickToBrick"
                  value={newSale.productSpecification.brickToBrick}
                  onChange={(event) =>
                    handleUpdate(setNewSale, "brickToBrick", event.target.value)
                  }
                />
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </div>
          <h6 style={{ fontWeight: 700 }}>Frame Size</h6>
          <div className="Form-section-1">
            <div className="Form-section-1-input" style={width10}>
              <div className="Form-input-container">
                <span className="Form-label">Width</span>
                <input
                  className="Form-input"
                  type="number"
                  name="frameSizeWidth"
                  value={newSale.productSpecification.frameSize.width}
                  onChange={(event) =>
                    handleUpdate(
                      setNewSale,
                      "frameSizeWidth",
                      event.target.value
                    )
                  }
                />
                <span className="Form-input-focus"></span>
              </div>
            </div>

            <div className="Form-section-1-input" style={width10}>
              <div className="Form-input-container">
                <span className="Form-label">Height</span>
                <input
                  className="Form-input"
                  type="number"
                  name="frameSizeHeight"
                  value={newSale.productSpecification.frameSize.height}
                  onChange={(event) =>
                    handleUpdate(
                      setNewSale,
                      "frameSizeHeight",
                      event.target.value
                    )
                  }
                />
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </div>
          <h6 style={{ fontWeight: 700 }}>Sill</h6>
          <div className="Form-section-1">
            <div className="Form-section-1-input" style={width20}>
              <div className="Form-input-container">
                <span className="Form-label">Type</span>
                <select
                  className="Form-input"
                  name="sill"
                  value={newSale.productSpecification.sill}
                  onChange={(event) =>
                    handleUpdate(setNewSale, "sill", event.target.value)
                  }
                  placeholder="Sill"
                >
                  <option value="">Select</option>
                  {sills.map((sill, index) => (
                    <option key={index} value={sill}>
                      {sill}
                    </option>
                  ))}
                </select>
                <span className="Form-input-focus"></span>
              </div>
            </div>
            <div className="Form-section-1-input" style={width15}>
              <div className="Form-input-container">
                <span className="Form-label">Extension Size</span>
                <select
                  className="Form-input"
                  name="sillExtension"
                  value={newSale.productSpecification.sillExtension}
                  onChange={(event) =>
                    handleUpdate(
                      setNewSale,
                      "sillExtension",
                      event.target.value
                    )
                  }
                  placeholder="Sill Extension"
                >
                  <option value="">Select</option>
                  <option value="1.5">1.5</option>
                  <option value="2.5">2.5</option>
                  <option value="3.5">3.5</option>
                  <option value="4.5">4.5</option>
                </select>
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </div>
          <h6 style={{ fontWeight: 700 }}>Jamb</h6>
          <div className="Form-section-1">
            <div className="Form-section-1-input" style={width10}>
              <div className="Form-input-container">
                <span className="Form-label">Size</span>
                <input
                  className="Form-input"
                  type="text"
                  list="jambList"
                  name="jambSize"
                  value={newSale.productSpecification.jamb.jambSize}
                  onChange={(event) =>
                    handleUpdate(
                      setNewSale,
                      "jambSize",
                      event.target.value,
                      "jamb"
                    )
                  }
                />

                <span className="Form-input-focus"></span>
                <datalist id="jambList">
                  <option value="6.625">6.625</option>
                  <option value="4.625">4.625</option>
                </datalist>
              </div>
            </div>

            <div className="Form-section-1-input" style={width20}>
              <div className="Form-input-container">
                <span className="Form-label">Material</span>
                <select
                  className="Form-input"
                  name="jambMaterial"
                  value={newSale.productSpecification.jamb.jambMaterial}
                  onChange={(event) =>
                    handleUpdate(
                      setNewSale,
                      "jambMaterial",
                      event.target.value,
                      "jamb"
                    )
                  }
                >
                  <option value="Composite Smooth">Composite Smooth</option>
                  <option value="Composite Woodgrain">
                    Composite Woodgrain
                  </option>
                  <option value="Vinyl Wrap">Vinyl Wrap</option>
                  <option value="See Notes">See Notes</option>
                </select>
                <span className="Form-input-focus"></span>
              </div>
            </div>
          </div>
          <h6 style={{ fontWeight: 700 }}>Doors</h6>
          <div className="FormSaleDoor-door-section">
            {/* Door Left */}
            <Collapsible
              className="collapsible-section"
              trigger="Door Left"
              open={true}
            >
              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Material</span>
                  <select
                    className="Form-input"
                    name="doorMaterial"
                    value={newSale.productSpecification.doorLeft.doorMaterial}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorMaterial",
                        event.target.value,
                        "doorLeftDoor"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="Steel">Steel</option>
                    <option value="Fibreglass">Fibreglass</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Brand</span>
                  <select
                    className="Form-input"
                    name="doorBrand"
                    value={newSale.productSpecification.doorLeft.doorBrand}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorBrand",
                        event.target.value,
                        "doorLeftDoor"
                      )
                    }
                  >
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "" && (
                      <option value="">
                        Please Select Door Material First
                      </option>
                    )}
                    {newSale.productSpecification.doorLeft.doorMaterial !==
                      "" && <option value="">Select</option>}
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "Steel" && <option value="Dusco">Dusco</option>}
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "Steel" && <option value="Novatech">Novatech</option>}
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "Steel" && <option value="Trutech">Trutech</option>}
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "Fibreglass" && <option value="Mennie">Mennie</option>}
                    {newSale.productSpecification.doorLeft.doorMaterial ===
                      "Fibreglass" && (
                      <option value="Richersons">Richersons</option>
                    )}
                    {newSale.productSpecification.doorLeft.doorMaterial !==
                      "" && <option value="Other">Other</option>}
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Style</span>
                  <select
                    className="Form-input"
                    name="doorStyle"
                    value={newSale.productSpecification.doorLeft.doorStyle}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorStyle",
                        event.target.value,
                        "doorLeftDoor"
                      )
                    }
                  >
                    {newSale.productSpecification.doorLeft.doorBrand === "" && (
                      <option value="">Please Select Door Brand First</option>
                    )}
                    {newSale.productSpecification.doorLeft.doorBrand !== "" && (
                      <option value="">Select</option>
                    )}
                    {DoorStyles.map((doorStyle) => {
                      if (
                        newSale.productSpecification.doorLeft.doorBrand ===
                        doorStyle.brand
                      ) {
                        return (
                          <option value={doorStyle.style}>
                            {doorStyle.style}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint Out</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintOut"
                      value={newSale.productSpecification.doorLeft.paintOut}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintOut",
                          event.target.value,
                          "doorLeftDoor"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint In</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintIn"
                      value={newSale.productSpecification.doorLeft.paintIn}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintIn",
                          event.target.value,
                          "doorLeftDoor"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Width</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabWidth"
                      value={
                        newSale.productSpecification.doorLeft.doorSlabWidth
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabWidth",
                          event.target.value,
                          "doorLeftDoor"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Height</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabHeight"
                      value={
                        newSale.productSpecification.doorLeft.doorSlabHeight
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabHeight",
                          event.target.value,
                          "doorLeftDoor"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Insert</span>
                  <select
                    className="Form-input"
                    name="doorInsert"
                    value={
                      newSale.productSpecification.doorLeft.doorInsert
                        .doorInsert
                    }
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorInsert",
                        event.target.value,
                        "doorLeftInsert"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
                <span className="Form-error-message">
                  {validationErrors.length > 0 &&
                    validationErrors.includes("doorInsert") &&
                    "Required"}
                </span>
              </div>

              {newSale.productSpecification.doorLeft.doorInsert.doorInsert ===
                "Yes" && (
                <div>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width20}>
                      <div className="Form-input-container">
                        <span className="Form-label">Insert Design</span>
                        <input
                          className="Form-input"
                          type="text"
                          list="insertDesigns"
                          name="design"
                          value={
                            newSale.productSpecification.doorLeft.doorInsert
                              .design
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "design",
                              event.target.value,
                              "doorLeftInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                        <datalist id="insertDesigns">
                          {InsertDesigns.map((insertDesign) => {
                            return <option value={`${insertDesign.design}`} />;
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>

                  <h6 className="Form-label">Size</h6>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Width</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="width"
                          value={
                            newSale.productSpecification.doorLeft.doorInsert
                              .width
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "width",
                              event.target.value,
                              "doorLeftInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Height</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="height"
                          value={
                            newSale.productSpecification.doorLeft.doorInsert
                              .height
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "height",
                              event.target.value,
                              "doorLeftInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>

                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Quantity</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="quantity"
                          value={
                            newSale.productSpecification.doorLeft.doorInsert
                              .quantity
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "quantity",
                              event.target.value,
                              "doorLeftInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Position</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="position"
                          value={
                            newSale.productSpecification.doorLeft.doorInsert
                              .position
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "position",
                              event.target.value,
                              "doorLeftInsert"
                            )
                          }
                          placeholder="Center"
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Type</span>
                    <select
                      className="Form-input"
                      name="lockType"
                      value={
                        newSale.productSpecification.doorLeft.lock.lockType
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockType",
                          event.target.value,
                          "doorLeftLock"
                        )
                      }
                    >
                      {lockTypes.map((lockType, index) => (
                        <option key={index} value={lockType}>
                          {lockType}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Distance From Top</span>
                    <input
                      className="Form-input"
                      name="distanceFromTop"
                      value={
                        newSale.productSpecification.doorLeft.lock
                          .distanceFromTop
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "distanceFromTop",
                          event.target.value,
                          "doorLeftLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Style</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="lockStyle"
                      value={
                        newSale.productSpecification.doorLeft.lock.lockStyle
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockStyle",
                          event.target.value,
                          "doorLeftLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Colour</span>
                    <select
                      className="Form-input"
                      name="hingeColour"
                      value={
                        newSale.productSpecification.doorLeft.hinge.hingeColour
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "hingeColour",
                          event.target.value,
                          "doorLeftHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      {hingeColours.map((hingeColour, index) => (
                        <option key={index} value={hingeColour}>
                          {hingeColour}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Self Closing</span>
                    <select
                      className="Form-input"
                      name="selfClosing"
                      value={
                        newSale.productSpecification.doorLeft.hinge.selfClosing
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "selfClosing",
                          event.target.value,
                          "doorLeftHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Position</span>
                    <select
                      className="Form-input"
                      name="position"
                      value={
                        newSale.productSpecification.doorLeft.hinge.position
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "position",
                          event.target.value,
                          "doorLeftHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Left">Left</option>
                      <option value="Right">Right</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Open</span>
                    <select
                      className="Form-input"
                      name="open"
                      value={newSale.productSpecification.doorLeft.open}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "open",
                          event.target.value,
                          "doorLeft"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>
            </Collapsible>

            {/* Middle Door */}
            <Collapsible
              className="collapsible-section"
              trigger="Middle Door"
              open={
                newSale.productSpecification.doorSystem === "Double Door" ||
                newSale.productSpecification.doorSystem === "Triple Door"
                  ? true
                  : false
              }
            >
              <button
                className="btn standard-btn"
                id="doorMiddlebutton"
                onClick={copyDoorFields}
              >
                Copy Door Left
              </button>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Material</span>
                  <select
                    className="Form-input"
                    name="doorMaterial"
                    value={newSale.productSpecification.doorMiddle.doorMaterial}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorMaterial",
                        event.target.value,
                        "doorMiddle"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="Steel">Steel</option>
                    <option value="Fibreglass">Fibreglass</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Brand</span>
                  <select
                    className="Form-input"
                    name="doorBrand"
                    value={newSale.productSpecification.doorMiddle.doorBrand}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorBrand",
                        event.target.value,
                        "doorMiddle"
                      )
                    }
                  >
                    {newSale.productSpecification.doorMiddle.doorMaterial ===
                      "" && (
                      <option value="">
                        Please Select Door Material First
                      </option>
                    )}
                    {newSale.productSpecification.doorMiddle.doorMaterial !==
                      "" && <option value="">Select</option>}
                    {newSale.productSpecification.doorMiddle.doorMaterial ===
                      "Steel" && <option value="Dusco">Dusco</option>}
                    {newSale.productSpecification.doorMiddle.doorMaterial ===
                      "Steel" && <option value="Novatech">Novatech</option>}
                    {newSale.productSpecification.doorMiddle.doorMaterial ===
                      "Fibreglass" && <option value="Mennie">Mennie</option>}
                    {newSale.productSpecification.doorMiddle.doorMaterial ===
                      "Fibreglass" && (
                      <option value="Richersons">Richersons</option>
                    )}
                    {newSale.productSpecification.doorLeft.doorMaterial !==
                      "" && <option value="Other">Other</option>}
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Style</span>
                  <select
                    className="Form-input"
                    name="doorStyle"
                    value={newSale.productSpecification.doorMiddle.doorStyle}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorStyle",
                        event.target.value,
                        "doorMiddle"
                      )
                    }
                  >
                    {newSale.productSpecification.doorMiddle.doorBrand ===
                      "" && (
                      <option value="">Please Select Door Brand First</option>
                    )}
                    {newSale.productSpecification.doorMiddle.doorBrand !==
                      "" && <option value="">Select</option>}
                    {DoorStyles.map((doorStyle) => {
                      if (
                        newSale.productSpecification.doorMiddle.doorBrand ===
                        doorStyle.brand
                      ) {
                        return (
                          <option value={doorStyle.style}>
                            {doorStyle.style}
                          </option>
                        );
                      }
                    })}
                  </select>

                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint Out</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintOut"
                      value={newSale.productSpecification.doorMiddle.paintOut}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintOut",
                          event.target.value,
                          "doorMiddle"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint In</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintIn"
                      value={newSale.productSpecification.doorMiddle.paintIn}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintIn",
                          event.target.value,
                          "doorMiddle"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Width</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabWidth"
                      value={
                        newSale.productSpecification.doorMiddle.doorSlabWidth
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabWidth",
                          event.target.value,
                          "doorMiddle"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Height</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabHeight"
                      value={
                        newSale.productSpecification.doorMiddle.doorSlabHeight
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabHeight",
                          event.target.value,
                          "doorMiddle"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Insert</span>
                  <select
                    className="Form-input"
                    name="doorInsert"
                    value={
                      newSale.productSpecification.doorMiddle.doorInsert
                        .doorInsert
                    }
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorInsert",
                        event.target.value,
                        "doorMiddleInsert"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
                <span className="Form-error-message">
                  {validationErrors.length > 0 &&
                    validationErrors.includes("doorInsert") &&
                    "Required"}
                </span>
              </div>

              {newSale.productSpecification.doorMiddle.doorInsert.doorInsert ===
                "Yes" && (
                <div>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width20}>
                      <div className="Form-input-container">
                        <span className="Form-label">Insert Design</span>
                        <input
                          className="Form-input"
                          type="text"
                          list="insertDesigns"
                          name="design"
                          value={
                            newSale.productSpecification.doorMiddle.doorInsert
                              .design
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "design",
                              event.target.value,
                              "doorMiddleInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                        <datalist id="insertDesigns">
                          {InsertDesigns.map((insertDesign) => {
                            return <option value={`${insertDesign.design}`} />;
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>

                  <h6 className="Form-label">Size</h6>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Width</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="width"
                          value={
                            newSale.productSpecification.doorMiddle.doorInsert
                              .width
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "width",
                              event.target.value,
                              "doorMiddleInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Height</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="height"
                          value={
                            newSale.productSpecification.doorMiddle.doorInsert
                              .height
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "height",
                              event.target.value,
                              "doorMiddleInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>

                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Quantity</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="quantity"
                          value={
                            newSale.productSpecification.doorMiddle.doorInsert
                              .quantity
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "quantity",
                              event.target.value,
                              "doorMiddleInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Position</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="position"
                          value={
                            newSale.productSpecification.doorMiddle.doorInsert
                              .position
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "position",
                              event.target.value,
                              "doorMiddleInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Type</span>
                    <select
                      className="Form-input"
                      name="lockType"
                      value={
                        newSale.productSpecification.doorMiddle.lock.lockType
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockType",
                          event.target.value,
                          "doorMiddleLock"
                        )
                      }
                    >
                      {lockTypes.map((lockType, index) => (
                        <option key={index} value={lockType}>
                          {lockType}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Distance From Top</span>
                    <input
                      className="Form-input"
                      name="distanceFromTop"
                      value={
                        newSale.productSpecification.doorMiddle.lock
                          .distanceFromTop
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "distanceFromTop",
                          event.target.value,
                          "doorMiddleLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Style</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="lockStyle"
                      value={
                        newSale.productSpecification.doorMiddle.lock.lockStyle
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockStyle",
                          event.target.value,
                          "doorMiddleLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Colour</span>
                    <select
                      className="Form-input"
                      name="hingeColour"
                      value={
                        newSale.productSpecification.doorMiddle.hinge
                          .hingeColour
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "hingeColour",
                          event.target.value,
                          "doorMiddleHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      {hingeColours.map((hingeColour, index) => (
                        <option key={index} value={hingeColour}>
                          {hingeColour}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Self Closing</span>
                    <select
                      className="Form-input"
                      name="selfClosing"
                      value={
                        newSale.productSpecification.doorMiddle.hinge
                          .selfClosing
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "selfClosing",
                          event.target.value,
                          "doorMiddleHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Position</span>
                    <select
                      className="Form-input"
                      name="position"
                      value={
                        newSale.productSpecification.doorMiddle.hinge.position
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "position",
                          event.target.value,
                          "doorMiddleHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Left">Left</option>
                      <option value="Right">Right</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Open</span>
                    <select
                      className="Form-input"
                      name="open"
                      value={newSale.productSpecification.doorMiddle.open}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "open",
                          event.target.value,
                          "doorMiddle"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>
            </Collapsible>

            {/* Right Door */}
            <Collapsible
              className="collapsible-section"
              trigger="Right Door"
              open={
                newSale.productSpecification.doorSystem === "Triple Door"
                  ? true
                  : false
              }
            >
              <button
                className="btn standard-btn"
                id="doorRightbutton"
                onClick={copyDoorFields}
              >
                Copy Door Left
              </button>
              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Material</span>
                  <select
                    className="Form-input"
                    name="doorMaterial"
                    value={newSale.productSpecification.doorRight.doorMaterial}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorMaterial",
                        event.target.value,
                        "doorRight"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="Steel">Steel</option>
                    <option value="Fibreglass">Fibreglass</option>
                    <option value="Other">Other</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Brand</span>
                  <select
                    className="Form-input"
                    name="doorBrand"
                    value={newSale.productSpecification.doorRight.doorBrand}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorBrand",
                        event.target.value,
                        "doorRight"
                      )
                    }
                  >
                    {newSale.productSpecification.doorRight.doorMaterial ===
                      "" && (
                      <option value="">
                        Please Select Door Material First
                      </option>
                    )}
                    {newSale.productSpecification.doorRight.doorMaterial !==
                      "" && <option value="">Select</option>}
                    {newSale.productSpecification.doorRight.doorMaterial ===
                      "Steel" && <option value="Dusco">Dusco</option>}
                    {newSale.productSpecification.doorRight.doorMaterial ===
                      "Steel" && <option value="Novatech">Novatech</option>}
                    {newSale.productSpecification.doorRight.doorMaterial ===
                      "Fibreglass" && <option value="Mennie">Mennie</option>}
                    {newSale.productSpecification.doorRight.doorMaterial ===
                      "Fibreglass" && (
                      <option value="Richersons">Richersons</option>
                    )}
                    {newSale.productSpecification.doorLeft.doorMaterial !==
                      "" && <option value="Other">Other</option>}
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1-input" style={width20}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Style</span>
                  <select
                    className="Form-input"
                    name="doorStyle"
                    value={newSale.productSpecification.doorRight.doorStyle}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorStyle",
                        event.target.value,
                        "doorRight"
                      )
                    }
                  >
                    {newSale.productSpecification.doorRight.doorBrand ===
                      "" && (
                      <option value="">Please Select Door Brand First</option>
                    )}
                    {newSale.productSpecification.doorRight.doorBrand !==
                      "" && <option value="">Select</option>}
                    {DoorStyles.map((doorStyle) => {
                      if (
                        newSale.productSpecification.doorRight.doorBrand ===
                        doorStyle.brand
                      ) {
                        return (
                          <option value={doorStyle.style}>
                            {doorStyle.style}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint Out</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintOut"
                      value={newSale.productSpecification.doorRight.paintOut}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintOut",
                          event.target.value,
                          "doorRight"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Paint In</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="paintIn"
                      value={newSale.productSpecification.doorRight.paintIn}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "paintIn",
                          event.target.value,
                          "doorRight"
                        )
                      }
                      placeholder="Factory White"
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Width</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabWidth"
                      value={
                        newSale.productSpecification.doorRight.doorSlabWidth
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabWidth",
                          event.target.value,
                          "doorRight"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Height</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="doorSlabHeight"
                      value={
                        newSale.productSpecification.doorRight.doorSlabHeight
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "doorSlabHeight",
                          event.target.value,
                          "doorRight"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Door Insert</span>
                  <select
                    className="Form-input"
                    name="doorInsert"
                    value={
                      newSale.productSpecification.doorRight.doorInsert
                        .doorInsert
                    }
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "doorInsert",
                        event.target.value,
                        "doorRightInsert"
                      )
                    }
                  >
                    <option value="">Select</option>
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
                <span className="Form-error-message">
                  {validationErrors.length > 0 &&
                    validationErrors.includes("doorInsert") &&
                    "Required"}
                </span>
              </div>

              {newSale.productSpecification.doorRight.doorInsert.doorInsert ===
                "Yes" && (
                <div>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width20}>
                      <div className="Form-input-container">
                        <span className="Form-label">Insert Design</span>
                        <input
                          className="Form-input"
                          type="text"
                          list="insertDesigns"
                          name="design"
                          value={
                            newSale.productSpecification.doorRight.doorInsert
                              .design
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "design",
                              event.target.value,
                              "doorRightInsert"
                            )
                          }
                          placeholder="Insert Design"
                        />
                        <span className="Form-input-focus"></span>
                        <datalist id="insertDesigns">
                          {InsertDesigns.map((insertDesign) => {
                            return <option value={`${insertDesign.design}`} />;
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>

                  <h6 className="Form-label">Size</h6>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Width</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="width"
                          value={
                            newSale.productSpecification.doorRight.doorInsert
                              .width
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "width",
                              event.target.value,
                              "doorRightInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Height</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="height"
                          value={
                            newSale.productSpecification.doorRight.doorInsert
                              .height
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "height",
                              event.target.value,
                              "doorRightInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>

                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Quantity</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="quantity"
                          value={
                            newSale.productSpecification.doorRight.doorInsert
                              .quantity
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "quantity",
                              event.target.value,
                              "doorRightInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Position</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="position"
                          value={
                            newSale.productSpecification.doorRight.doorInsert
                              .position
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "position",
                              event.target.value,
                              "doorRightInsert"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Type</span>
                    <select
                      className="Form-input"
                      name="lockType"
                      value={
                        newSale.productSpecification.doorRight.lock.lockType
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockType",
                          event.target.value,
                          "doorRightLock"
                        )
                      }
                    >
                      {lockTypes.map((lockType, index) => (
                        <option key={index} value={lockType}>
                          {lockType}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Distance From Top</span>
                    <input
                      className="Form-input"
                      name="distanceFromTop"
                      value={
                        newSale.productSpecification.doorRight.lock
                          .distanceFromTop
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "distanceFromTop",
                          event.target.value,
                          "doorRightLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width20}>
                  <div className="Form-input-container">
                    <span className="Form-label">Lock Style</span>
                    <input
                      className="Form-input"
                      type="text"
                      name="lockStyle"
                      value={
                        newSale.productSpecification.doorRight.lock.lockStyle
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "lockStyle",
                          event.target.value,
                          "doorRightLock"
                        )
                      }
                    />
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Colour</span>
                    <select
                      className="Form-input"
                      name="hingeColour"
                      value={
                        newSale.productSpecification.doorRight.hinge.hingeColour
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "hingeColour",
                          event.target.value,
                          "doorRightHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      {hingeColours.map((hingeColour, index) => (
                        <option key={index} value={hingeColour}>
                          {hingeColour}
                        </option>
                      ))}
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Self Closing</span>
                    <select
                      className="Form-input"
                      name="selfClosing"
                      value={
                        newSale.productSpecification.doorRight.hinge.selfClosing
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "selfClosing",
                          event.target.value,
                          "doorRightHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>

                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Hinge Position</span>
                    <select
                      className="Form-input"
                      name="position"
                      value={
                        newSale.productSpecification.doorRight.hinge.position
                      }
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "position",
                          event.target.value,
                          "doorRightHinge"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Left">Left</option>
                      <option value="Right">Right</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>

              <div className="Form-section-1">
                <div className="Form-section-1-input" style={width10}>
                  <div className="Form-input-container">
                    <span className="Form-label">Door Open</span>
                    <select
                      className="Form-input"
                      name="open"
                      value={newSale.productSpecification.doorRight.open}
                      onChange={(event) =>
                        handleUpdate(
                          setNewSale,
                          "open",
                          event.target.value,
                          "doorRight"
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </select>
                    <span className="Form-input-focus"></span>
                  </div>
                </div>
              </div>
            </Collapsible>
          </div>
          {/* SIDELIGHT FIELDS */}
          <h6 style={{ fontWeight: 700 }}>Side Lights</h6>
          <div className="FormSaleDoor-door-section">
            {/* SLIDELIGHT 1 */}
            <Collapsible
              className="collapsible-section"
              trigger="Left Sidelight"
              open={true}
            >
              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Side Light</span>
                  <select
                    className="Form-input"
                    name="sidelight"
                    value={newSale.productSpecification.sidelightLeft.sidelight}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "sidelight",
                        event.target.value,
                        "sidelightLeft"
                      )
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>
              {newSale.productSpecification.sidelightLeft.sidelight ===
                "Yes" && (
                <div>
                  <div className="Form-section-1-input" style={width10}>
                    <div className="Form-input-container">
                      <span className="Form-label">Sidelight Panels</span>
                      <select
                        className="Form-input"
                        name="panel"
                        value={newSale.productSpecification.sidelightLeft.panel}
                        onChange={(event) =>
                          handleUpdate(
                            setNewSale,
                            "panel",
                            event.target.value,
                            "sidelightLeft"
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                      <span className="Form-input-focus"></span>
                    </div>
                  </div>

                  {newSale.productSpecification.sidelightLeft.panel > 0 && (
                    <div>
                      <h6 className="Form-label">Panel 1 Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelWidth"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .panelWidth
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelWidth",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelHeight"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .panelHeight
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelHeight",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {newSale.productSpecification.sidelightLeft.panel > 1 && (
                    <div>
                      <h6 className="Form-label">Panel 2 Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelWidth2"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .panelWidth2
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelWidth2",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelHeight2"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .panelHeight2
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelHeight2",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="Form-section-1-input" style={width10}>
                    <div className="Form-input-container">
                      <span className="Form-label">Glass Insert</span>
                      <select
                        className="Form-input"
                        name="insert"
                        value={
                          newSale.productSpecification.sidelightLeft.insert
                        }
                        onChange={(event) =>
                          handleUpdate(
                            setNewSale,
                            "insert",
                            event.target.value,
                            "sidelightLeft"
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <span className="Form-input-focus"></span>
                    </div>
                  </div>

                  {newSale.productSpecification.sidelightLeft.insert ===
                    "Yes" && (
                    <div>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width20}>
                          <div className="Form-input-container">
                            <span className="Form-label">Insert Design</span>
                            <input
                              className="Form-input"
                              type="text"
                              list="insertDesigns"
                              name="insertDesign"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .insertDesign
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertDesign",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />

                            <datalist id="insertDesigns">
                              {InsertDesigns.map((insertDesign) => {
                                return (
                                  <option value={`${insertDesign.design}`} />
                                );
                              })}
                            </datalist>
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>

                      <h6 className="Form-label">Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="insertWidth"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .insertWidth
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertWidth",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="insertHeight"
                              value={
                                newSale.productSpecification.sidelightLeft
                                  .insertHeight
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertHeight",
                                  event.target.value,
                                  "sidelightLeft"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Collapsible>

            {/* SLIDELIGHT 2 */}
            <Collapsible
              className="collapsible-section"
              trigger="Right Sidelight"
              open={true}
            >
              <button
                className="btn standard-btn"
                id="sidelightbutton"
                onClick={copyDoorFields}
              >
                Copy Left Sidelight
              </button>
              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Side Light</span>
                  <select
                    className="Form-input"
                    name="sidelight"
                    value={
                      newSale.productSpecification.sidelightRight.sidelight
                    }
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "sidelight",
                        event.target.value,
                        "sidelightRight"
                      )
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>
              {newSale.productSpecification.sidelightRight.sidelight ===
                "Yes" && (
                <div>
                  <div className="Form-section-1-input" style={width10}>
                    <div className="Form-input-container">
                      <span className="Form-label">Sidelight Panels</span>
                      <select
                        className="Form-input"
                        name="panel"
                        value={
                          newSale.productSpecification.sidelightRight.panel
                        }
                        onChange={(event) =>
                          handleUpdate(
                            setNewSale,
                            "panel",
                            event.target.value,
                            "sidelightRight"
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                      </select>
                      <span className="Form-input-focus"></span>
                    </div>
                  </div>

                  {newSale.productSpecification.sidelightRight.panel > 0 && (
                    <div>
                      <h6 className="Form-label">Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelWidth"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .panelWidth
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelWidth",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelHeight"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .panelHeight
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelHeight",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {newSale.productSpecification.sidelightRight.panel > 1 && (
                    <div>
                      <h6 className="Form-label">Panel 2 Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelWidth2"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .panelWidth2
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelWidth2",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="panelHeight2"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .panelHeight2
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "panelHeight2",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="Form-section-1-input" style={width10}>
                    <div className="Form-input-container">
                      <span className="Form-label">Glass Insert</span>
                      <select
                        className="Form-input"
                        name="insert"
                        value={
                          newSale.productSpecification.sidelightRight.insert
                        }
                        onChange={(event) =>
                          handleUpdate(
                            setNewSale,
                            "insert",
                            event.target.value,
                            "sidelightRight"
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </select>
                      <span className="Form-input-focus"></span>
                    </div>
                  </div>

                  {newSale.productSpecification.sidelightRight.insert ===
                    "Yes" && (
                    <div>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width20}>
                          <div className="Form-input-container">
                            <span className="Form-label">Insert Design</span>
                            <input
                              className="Form-input"
                              type="text"
                              list="insertDesigns"
                              name="insertDesign"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .insertDesign
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertDesign",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />

                            <span className="Form-input-focus"></span>
                            <datalist id="insertDesigns">
                              {InsertDesigns.map((insertDesign) => {
                                return (
                                  <option value={`${insertDesign.design}`} />
                                );
                              })}
                            </datalist>
                          </div>
                        </div>
                      </div>

                      <h6 className="Form-label">Size</h6>
                      <div className="Form-section-1">
                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Width</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="insertWidth"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .insertWidth
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertWidth",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>

                        <div className="Form-section-1-input" style={width10}>
                          <div className="Form-input-container">
                            <span className="Form-label">Height</span>
                            <input
                              className="Form-input"
                              type="text"
                              name="insertHeight"
                              value={
                                newSale.productSpecification.sidelightRight
                                  .insertHeight
                              }
                              onChange={(event) =>
                                handleUpdate(
                                  setNewSale,
                                  "insertHeight",
                                  event.target.value,
                                  "sidelightRight"
                                )
                              }
                            />
                            <span className="Form-input-focus"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Collapsible>
          </div>
          {/* TRANSOM */}
          <h6 style={{ fontWeight: 700 }}>Transom</h6>
          <div className="FormSaleDoor-door-section">
            <Collapsible
              className="collapsible-section"
              trigger="Transom"
              open={true}
            >
              <div className="Form-section-1-input" style={width10}>
                <div className="Form-input-container">
                  <span className="Form-label">Transom</span>
                  <select
                    className="Form-input"
                    name="transomBox"
                    value={newSale.productSpecification.transom.transomBox}
                    onChange={(event) =>
                      handleUpdate(
                        setNewSale,
                        "transomBox",
                        event.target.value,
                        "transom"
                      )
                    }
                  >
                    <option value="No">No</option>
                    <option value="Yes">Yes</option>
                  </select>
                  <span className="Form-input-focus"></span>
                </div>
              </div>

              {newSale.productSpecification.transom.transomBox === "Yes" && (
                <div>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Transom Type</span>
                        <select
                          className="Form-input"
                          name="transomType"
                          value={
                            newSale.productSpecification.transom.transomType
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomType",
                              event.target.value,
                              "transom"
                            )
                          }
                        >
                          <option value="">Select</option>
                          <option value="Regular">Regular</option>
                          <option value="Arc">Arc</option>
                        </select>
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>

                  {newSale.productSpecification.transom.transomType ===
                    "Arc" && (
                    <div className="Form-section-1">
                      <div className="Form-section-1-input" style={width10}>
                        <div className="Form-input-container">
                          <span className="Form-label">Arc Height</span>
                          <input
                            className="Form-input"
                            name="transomArcHeight"
                            value={
                              newSale.productSpecification.transom
                                .transomArcHeight
                            }
                            onChange={(event) =>
                              handleUpdate(
                                setNewSale,
                                "transomArcHeight",
                                event.target.value,
                                "transom"
                              )
                            }
                          />
                          <span className="Form-input-focus"></span>
                        </div>
                      </div>

                      <div className="Form-section-1-input" style={width10}>
                        <div className="Form-input-container">
                          <span className="Form-label">Arc Legs</span>
                          <input
                            className="Form-input"
                            name="transomArcLegs"
                            value={
                              newSale.productSpecification.transom
                                .transomArcLegs
                            }
                            onChange={(event) =>
                              handleUpdate(
                                setNewSale,
                                "transomArcLegs",
                                event.target.value,
                                "transom"
                              )
                            }
                          />
                          <span className="Form-input-focus"></span>
                        </div>
                      </div>
                    </div>
                  )}

                  <h6 className="Form-label"># Of Transom Box</h6>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Row</span>
                        <input
                          className="Form-input"
                          name="transomRow"
                          value={
                            newSale.productSpecification.transom.transomRow
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomRow",
                              event.target.value,
                              "transom"
                            )
                          }
                          placeholder="1"
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Column</span>
                        <input
                          className="Form-input"
                          name="transomColumn"
                          value={
                            newSale.productSpecification.transom.transomColumn
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomColumn",
                              event.target.value,
                              "transom"
                            )
                          }
                          placeholder="1"
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>
                  </div>

                  <h6 className="Form-label">Insert</h6>
                  <div className="Form-section-1">
                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Width</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="transomInsertWidth"
                          value={
                            newSale.productSpecification.transom
                              .transomInsertWidth
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomInsertWidth",
                              event.target.value,
                              "transom"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width10}>
                      <div className="Form-input-container">
                        <span className="Form-label">Height</span>
                        <input
                          className="Form-input"
                          type="text"
                          name="transomInsertHeight"
                          value={
                            newSale.productSpecification.transom
                              .transomInsertHeight
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomInsertHeight",
                              event.target.value,
                              "transom"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                      </div>
                    </div>

                    <div className="Form-section-1-input" style={width20}>
                      <div className="Form-input-container">
                        <span className="Form-label">Design</span>
                        <input
                          className="Form-input"
                          type="text"
                          list="insertDesigns"
                          name="transomInsertDesign"
                          value={
                            newSale.productSpecification.transom
                              .transomInsertDesign
                          }
                          onChange={(event) =>
                            handleUpdate(
                              setNewSale,
                              "transomInsertDesign",
                              event.target.value,
                              "transom"
                            )
                          }
                        />
                        <span className="Form-input-focus"></span>
                        <datalist id="insertDesigns">
                          {InsertDesigns.map((insertDesign) => {
                            return <option value={`${insertDesign.design}`} />;
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Collapsible>
          </div>

          {/* Door Render Image */}
          <br />
          <br />
          <div>
            {/* <button className="btn btn-secondary" onClick={renderDoor}>
              Render Door
            </button> */}

            <div>
              {renderValidations.length > 0 && (
                <div>
                  <p style={{ color: "red", fontWeight: "bold" }}>
                    To render an image of the doorLeft, please fill out the
                    following size:
                  </p>
                  {renderValidations.map((validation) => {
                    return <p style={{ color: "red" }}>*{validation}</p>;
                  })}
                </div>
              )}
            </div>
          </div>
          <br />
          <br />
        </div>
      )}
    </div>
  );
};

export default FormSaleDoor;
