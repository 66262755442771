import React from "react";

const width10 = {
  width: "10%",
};

const width15 = {
  width: "15%",
};

const width20 = {
  width: "20%",
};

const width30 = {
  width: "30%",
};

const FormSaleWorkOrder = (props) => {
  const { newSale, setNewSale, validationErrors } = props;

  const handleUpdate = (set, field, value) => {
    if (field === "labourCost") {
      set((state) => ({
        ...state,
        [field]: value,
      }));
    } else {
      set((state) => ({
        ...state,
        workOrder: {
          ...state.workOrder,
          [field]: value,
        },
      }));
    }
  };

  return (
    <div>
      {/* Work Order Fields */}
      <h6 style={{ fontWeight: 700 }}>Installation</h6>

      <div className="Form-section-1-input" style={width10}>
        <div className="Form-input-container" data-validate="Name is required">
          <span className="Form-label">Installation Required</span>
          <select
            className="Form-input"
            name="installationRequired"
            value={newSale.workOrder.installationRequired}
            onChange={(event) =>
              handleUpdate(
                setNewSale,
                "installationRequired",
                event.target.value
              )
            }
          >
            <option value="">Select</option>
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>

          <span className="Form-input-focus"></span>
        </div>
        <span className="Form-error-message">
          {validationErrors.length > 0 &&
            validationErrors.includes("workOrder") &&
            "Required"}
        </span>
      </div>

      {newSale.workOrder.installationRequired === "Yes" && (
        <div className="Form-section-1-input" style={width10}>
          <div
            className="Form-input-container"
            data-validate="Name is required"
          >
            <span className="Form-label">Labour Cost</span>
            <input
              className="Form-input"
              type="text"
              name="labourCost"
              value={newSale.labourCost}
              onChange={(event) =>
                handleUpdate(setNewSale, "labourCost", event.target.value)
              }
            />
            <span className="Form-input-focus"></span>
          </div>
          <span className="form-error-message">{/* error message */}</span>
        </div>
      )}
    </div>
  );
};

export default FormSaleWorkOrder;
